import { Card } from "antd";
import React, { ReactNode } from "react";
import { Grid } from "antd";

export const CardTitle = ({
  title,
  className,
  extra,
  actions,
}: {
  title: string;
  className?: string;
  extra?: ReactNode;
  actions?: ReactNode[];
}) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  let isMobile = xs;
  return (
    <Card
      // className={`card-title ${className}`}
      title={<div className="card-title__title">{title}</div>}
      bordered={false}
      hoverable={false}
      size="small"
      extra={isMobile ? [] : extra}
      styles={{
        body: isMobile ? { textAlign: "center" } : { display: "none" },
      }}
      actions={actions || []}
    >
      {extra}
    </Card>
  );
};
