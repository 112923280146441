import { Form, Input, DatePicker, Button, Modal, Row, Col } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { ToDoProps } from "../../../interfaces/interfaces";
import { TODO_INITIAL_STATE } from "../../../utils/data";

interface Props {
  modalStatus: {
    open: boolean;
    create: boolean;
    update: boolean;
    payload?: ToDoProps | null;
  } | null;
  onCancel: () => void;
  onSubmit: (values: ToDoProps) => void;
}

export const ModalCreateOrUpdateToDo = ({
  modalStatus,
  onCancel,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState<ToDoProps>(TODO_INITIAL_STATE);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(TODO_INITIAL_STATE);
      setValues(TODO_INITIAL_STATE);
    }
  }, [values]);

  useEffect(() => {
    if (modalStatus && modalStatus.payload) {
      setValues(modalStatus.payload);
    } else {
      setValues(TODO_INITIAL_STATE);
    }
  }, [modalStatus]);

  return (
    <Modal
      title="To-Do"
      open={modalStatus?.open || false}
      onOk={() => {
        onCancel();
        form.resetFields();
        setValues(TODO_INITIAL_STATE);
      }}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setValues(TODO_INITIAL_STATE);
      }}
      footer={null}
      centered
    >
      <Form
        layout="vertical"
        onFinish={() => onSubmit({ ...modalStatus?.payload, ...values })}
        form={form}
        initialValues={values}
      >
        <Row gutter={24}>
          {/* Left Column */}
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input
                maxLength={100}
                placeholder="Enter activity description"
                onChange={(event) =>
                  setValues({ ...values, description: event.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Due Date">
              <DatePicker
                value={values.dueDate && dayjs(values.dueDate)}
                format="MM/DD/YYYY"
                style={{ width: "100%" }}
                onChange={(value) => {
                  setValues({
                    ...values,
                    dueDate: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Buttons */}
        <Row justify="end">
          <Col>
            <Button
              type="default"
              onClick={onCancel}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
