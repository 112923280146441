import { Button, Card, Row } from "antd";
import Typography from "antd/es/typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ColumnComponent } from "../../components/ui";
const { Title, Paragraph, Link } = Typography;

export const PresentationPage = () => {
  const navigate = useNavigate();
  return (
    <div className="general-view">
      <Row gutter={[16, 0]}>
        <ColumnComponent xl={12}>
          <Card
            title={<Title level={4}>Welcome To CRM</Title>}
            actions={[
              <Button type="link" onClick={() => navigate("/login")}>
                Already an user? Login
              </Button>,
              <Button type="link" onClick={() => {}}>
                Try our demo today!
              </Button>,
            ]}
          >
            <Title level={4}>
              Simplify and Automate Your Invoice Collections Process
            </Title>
            <Paragraph>CRM offers...</Paragraph>
            <Title level={4}>Key Features</Title>
            <ul>
              <li>
                <strong>Centralized Tracking:</strong> Monitor all your
                outstanding invoices in one place. Get a clear view of what’s
                due, overdue, and paid.
              </li>
              <li>
                <strong>Prioritization:</strong> Automatically prioritize
                invoices based on due dates, amounts, and customer history,
                ensuring you focus on the most critical collections first.
              </li>
              <li>
                <strong>Detailed Reporting:</strong> Generate comprehensive
                reports to analyze your collections performance. Track key
                metrics such as average payment times, and more.
              </li>
              <li>
                <strong>Easy Integration:</strong> Seamlessly integrate with
                QuickBooks to import your invoices and customer data, keeping
                everything up-to-date without any manual effort.
              </li>
            </ul>
          </Card>
        </ColumnComponent>
        <ColumnComponent xl={12}>
          <Card
            title={<Title level={4}>Get Started Today</Title>}
            style={{ height: "100%" }}
          >
            <Title level={4}>How It Works</Title>
            <ol>
              <li>
                <strong>Connect to QuickBooks:</strong> Link your QuickBooks
                account to CRM with a few simple steps.
              </li>
              <li>
                <strong>Import Invoices:</strong> Automatically import all your
                invoices from QuickBooks.
              </li>
              <li>
                <strong>Monitor and Manage: </strong> Use our centralized
                dashboard to track, prioritize, and manage your collections
                process.
              </li>
            </ol>
            <Title level={4}>
              Ready to take control of your collections process?
            </Title>

            <Paragraph>
              Sign up for CRM today and see how easy managing your receivables
              can be.
            </Paragraph>
            <ul>
              <li>
                Email:{" "}
                <Link href="mailto:info@quattroapps.com">
                  info@quattroapps.com
                </Link>
              </li>
              <li>
                Phone: <Link href="+1432-363-4009">432-363-4009</Link>
              </li>
              <li>
                Visit our website at:{" "}
                <Link href="#" target="_blank">
                  quattroapps.app
                </Link>
              </li>
            </ul>
          </Card>
        </ColumnComponent>
      </Row>
    </div>
  );
};
