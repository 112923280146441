import React, { useContext, useEffect, useState } from "react";
import { Input, Table } from "antd";
import { CustomerProps } from "../../interfaces/interfaces";

import DataContext from "../../context/DataContext";
import { ColumnsType } from "antd/es/table";
import { TablePaginationConfig } from "antd/lib";
import { FilterValue } from "antd/es/table/interface";
const { Search } = Input;

interface Props {
  customersSelected: string[];
  onSave: (customers: React.Key[]) => void;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const CustomerListWithNoDetails = ({
  customersSelected,
  onSave,
}: Props) => {
  const [tableParams, setTableParams] = useState<TableParams>({});
  const { customerByClient } = useContext(DataContext);
  const [selectedRowsToEdit, setSelectedRowToEdit] = useState<React.Key[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (customersSelected.length > 0) {
      setSelectedRowToEdit(customersSelected);
    } else {
      setSelectedRowToEdit([]);
    }
  }, [customersSelected]);

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: 100,
        total: customerByClient.length,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
  }, [customerByClient]);

  // Filter customers based on search input
  const filteredCustomers = customerByClient.filter((customer) =>
    customer.DisplayName.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns: ColumnsType<CustomerProps> = [
    {
      title: "Customers",
      dataIndex: "DisplayName",
      key: "DisplayName",
      className: "table-row",
      sorter: {
        compare: (a, b) => a.DisplayName.localeCompare(b.DisplayName),
        multiple: 1,
      },
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  const onChangeSelectedRowsToEdit = (newSelectedRowToEdit: React.Key[]) => {
    setSelectedRowToEdit(newSelectedRowToEdit);
    onSave(newSelectedRowToEdit);
  };

  const rowSelectionToEdit = {
    selectedRowKeys: selectedRowsToEdit,
    onChange: onChangeSelectedRowsToEdit,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  return (
    <>
      {/* <Search
        placeholder="Search customers..."
        allowClear
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: 16 }}
      /> */}
      <Table
        columns={columns}
        rowSelection={rowSelectionToEdit}
        dataSource={filteredCustomers}
        pagination={tableParams.pagination}
        size="small"
        rowKey={"_id"}
        scroll={{ y: "60vh", x: "100%" }}
        tableLayout="auto"
      />
    </>
  );
};
