import React, { ReactNode, useContext, useEffect, useState } from "react";

import {
  Alert,
  Avatar,
  Button,
  Dropdown,
  Layout,
  Tooltip,
  message,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import DataContext from "../../context/DataContext";
import { MenuProps } from "antd/lib";
import { ModalPreferences } from "../../components/admin";
import { getDaysDiffNewAccount } from "../../utils/func/utils";

interface Props {
  children: ReactNode;
}
export const MiddlewareLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const [preferencesOpen, setPreferencesOpen] = useState(false);
  const [openAlertNewUser, setOpenAlertNewUser] = useState(false);
  const { userInfo, isDemoEnv, onLogout, role, clientInfo, profileInfo } =
    useContext(AuthContext);
  const [initials, setInitials] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const { dataError, dataSuccess, dataWarning } = useContext(DataContext);
  const [messageOpen, setMessageOpen] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    if (profileInfo?.newAccount?.isNewAccount) {
      setOpenAlertNewUser(true);
      const difference = getDaysDiffNewAccount(profileInfo.newAccount.date);
      difference && setDaysLeft(difference);
    } else {
      setOpenAlertNewUser(false);
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.name) {
        const name = userInfo.name.split(" ");
        setInitials(
          `${name[0] ? name[0].split("")[0] : ""}${
            name[1] ? name[1].split("")[0] : ""
          }`
        );
      } else {
        setInitials("CU");
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (dataError.status) error(dataError.message);
  }, [dataError]);

  useEffect(() => {
    if (dataSuccess.status) success(dataSuccess.message);
  }, [dataSuccess]);

  useEffect(() => {
    if (dataWarning.status) warning(dataWarning.message);
  }, [dataWarning]);

  const success = (message: string) => {
    if (!messageOpen) {
      messageApi.open({
        type: "success",
        content: message,
        onClose: () => setMessageOpen(false),
      });
    }
  };

  const error = (message: string) => {
    if (!messageOpen)
      messageApi.open({
        type: "error",
        content: message,
        onClose: () => setMessageOpen(false),
      });
  };

  const warning = (message: string) => {
    if (dataWarning.status) {
      messageApi.open({
        type: "warning",
        content: message,
        onClose: () => setMessageOpen(false),
      });
    } else {
      messageApi.destroy();
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Logout",
      key: "logout",
      onClick: () => onLogout(() => navigate("/login")),
    },
    {
      label: "Version 1.0.6",
      key: "version",
      disabled: true,
    },
  ];

  return (
    <>
      {isDemoEnv && (
        <Alert
          message={
            <>
              This is a demo version of CRM. Please contact the administrator to
              get the full version. Visit{" "}
              <a href="#" target="_blank">
                crm
              </a>{" "}
              for more Info
            </>
          }
          banner
          type="warning"
          action={
            <Button
              size="middle"
              type="primary"
              onClick={() => window.open("#/form")}
            >
              Get a Quote
            </Button>
          }
        />
      )}
      {openAlertNewUser && (
        <Alert
          message={
            <>
              Thank you for signing up! You have {daysLeft} days left in your
              free trial. Visit{" "}
              <a href="#" target="_blank">
                crm
              </a>{" "}
              for more Info
            </>
          }
          banner
          type="warning"
          closable
          action={
            <Button
              size="middle"
              type="primary"
              onClick={() => window.open("#/form")}
            >
              Get a Quote
            </Button>
          }
        />
      )}
      <Layout className="protected-layout">
        {contextHolder}
        <Layout>
          <Header className="protected-layout__header">
            <div></div>
            <Tooltip title={userInfo?.name} placement="left">
              <Dropdown menu={{ items }}>
                <Avatar
                  style={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    backgroundColor: "#17549a",
                  }}
                  gap={2}
                >
                  {initials}
                </Avatar>
              </Dropdown>
            </Tooltip>
          </Header>
          <Content className="protected-layout__dashboard">
            <Content className="protected-layout__dashboard__content">
              {children}
            </Content>
          </Content>
        </Layout>
      </Layout>
      <ModalPreferences
        open={preferencesOpen}
        onCancel={() => setPreferencesOpen(false)}
      />
    </>
  );
};
