import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Modal,
  Row,
  Col,
  Checkbox,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { TaskProps } from "../../interfaces/interfaces";
import DataContext from "../../context/DataContext";
import { TASK_INITIAL_STATE } from "../../utils/data";
import dayjs from "dayjs";
import { TagSelector } from "../ui";

const frequency_map: {
  [key: string]: number;
} = {
  Daily: 1,
  Weekly: 7,
  Monthly: 30,
  Yearly: 365,
};

interface Props {
  modalStatus: {
    open: boolean;
    create: boolean;
    update: boolean;
    payload?: TaskProps | null;
  } | null;
  onCancel: () => void;
  onSubmit: (values: TaskProps) => void;
}

export const ModalCreateOrUpdateTask = ({
  modalStatus,
  onCancel,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState<TaskProps>(TASK_INITIAL_STATE);
  const { usersList } = useContext(DataContext);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(TASK_INITIAL_STATE);
      setValues(TASK_INITIAL_STATE);
    }
  }, [values]);

  useEffect(() => {
    if (modalStatus && modalStatus.payload) {
      setValues(modalStatus.payload);
    } else {
      setValues(TASK_INITIAL_STATE);
    }
  }, [modalStatus]);

  return (
    <Modal
      title="Task"
      open={modalStatus?.open || false}
      width={1000}
      onOk={onCancel}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setValues(TASK_INITIAL_STATE);
      }}
      footer={null}
      centered
    >
      <Form
        layout="vertical"
        onFinish={() => onSubmit({ ...modalStatus?.payload, ...values })}
        form={form}
        initialValues={values}
      >
        <Row gutter={16}>
          {/* Type of Task */}
          <Col span={8}>
            <Form.Item
              label="Type of Task"
              name="typeOfTask"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select task type"
                onChange={(value) =>
                  setValues({ ...values, typeOfTask: value })
                }
                options={[
                  { label: "Call", value: "Call" },
                  { label: "Meeting", value: "Meeting" },
                  { label: "Follow-up", value: "Follow-up" },
                  { label: "Email", value: "Email" },
                  { label: "To do ", value: "To do" },
                  { label: "Other", value: "Other" },
                ]}
              />
            </Form.Item>
          </Col>
          {/* Priority */}
          <Col span={8}>
            <Form.Item label="Priority" name="priority">
              <Select
                placeholder="Select priority"
                onChange={(value) => setValues({ ...values, priority: value })}
                options={[
                  { label: "Low", value: "Low" },
                  { label: "Medium", value: "Medium" },
                  { label: "High", value: "High" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Tags">
              <TagSelector
                selectedTags={values.tags || []}
                setSelectedTags={(selectedTags) => {
                  setValues({ ...values, tags: selectedTags });
                }}
              />
            </Form.Item>
          </Col>
          {/* Description */}
          <Col span={12}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input
                onChange={(e) =>
                  setValues({ ...values, description: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          {/* Status */}
          <Col span={12}>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select status"
                onChange={(value) => setValues({ ...values, status: value })}
                options={[
                  { label: "Pending", value: "Pending" },
                  { label: "In Progress", value: "In Progress" },
                  { label: "Completed", value: "Completed" },
                  { label: "Cancelled", value: "Cancelled" },
                ]}
              />
            </Form.Item>
          </Col>
          {/* Start Date */}
          <Col span={12}>
            <Form.Item label="Task Date">
              <DatePicker
                popupStyle={{ position: "fixed", top: 250, left: 250 }}
                value={values.startDate && dayjs(values.startDate)}
                showTime
                format="MM/DD/YYYY HH:mm"
                style={{ width: "100%" }}
                onChange={(value) => {
                  if (!value) {
                    setValues({
                      ...values,
                      startDate: value,
                      nextDate: null,
                      isRecurring: false,
                      frequency: "Daily",
                    });
                  } else {
                    setValues({ ...values, startDate: value });
                  }
                }}
              />
            </Form.Item>
          </Col>
          {/* Repeat Task & Frequency */}
          <Col span={12}>
            <Form.Item name="isRecurring" valuePropName="checked" label=" ">
              <Checkbox
                disabled={!values.startDate}
                onChange={(e) => {
                  if (e.target.checked) {
                    const frequency =
                      frequency_map[values.frequency || "Daily"];
                    const startDate = dayjs(values.startDate);
                    let nextDate = null;
                    if (startDate) {
                      nextDate = startDate?.add(frequency, "days");
                    }
                    setValues({
                      ...values,
                      isRecurring: e.target.checked,
                      nextDate,
                    });
                  } else {
                    setValues({
                      ...values,
                      isRecurring: e.target.checked,
                      nextDate: null,
                    });
                  }
                }}
              >
                Set To Repeat
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Frequency" name="frequency">
              <Select
                placeholder="Select frequency"
                disabled={!values.isRecurring}
                onChange={(value) => {
                  const frequency = frequency_map[value];
                  const startDate = values.startDate;
                  const nextDate = startDate?.add(frequency, "days");
                  setValues({ ...values, nextDate, frequency: value });
                }}
                options={[
                  { label: "Daily", value: "Daily" },
                  { label: "Weekly", value: "Weekly" },
                  { label: "Monthly", value: "Monthly" },
                  { label: "Yearly", value: "Yearly" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Next Date">
              <DatePicker
                popupStyle={{ position: "fixed", top: 250, left: 450 }}
                showTime
                style={{ width: "100%" }}
                value={values.nextDate && dayjs(values.nextDate)}
                disabled={!values.isRecurring}
                format="MM/DD/YYYY HH:mm"
                onChange={(value, dateString) => {
                  console.log("Selected Time: ", value);
                  console.log("Formatted Selected Time: ", dateString);
                }}
              />
            </Form.Item>
          </Col>
          {/* Assigned To */}
          <Col span={12}>
            <Form.Item label="Assigned To">
              <Select
                value={values.assignedTo?._id}
                placeholder="Assign to user"
                options={usersList.map((user) => ({
                  label: user.name,
                  value: user._id,
                }))}
                onChange={(value) => {
                  const selected = usersList.find((user) => user._id === value);
                  setValues({ ...values, assignedTo: selected });
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          {/* Notes (Full Width) */}
          <Col span={12}>
            <Form.Item label="Notes" name="notes">
              <Input.TextArea
                placeholder="Enter additional details"
                onChange={(e) =>
                  setValues({ ...values, notes: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Buttons */}
        <Row justify="end" style={{ marginTop: 16 }}>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>
            Save Task
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
