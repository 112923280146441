import { io, Socket } from "socket.io-client";

const SERVER_URL = process.env.REACT_APP_SERVER || "http://localhost:4000"; // Update with your backend URL

let socket: Socket;

export const connectSocket = (userId: string) => {
  if (!socket) {
    socket = io(SERVER_URL, {
      withCredentials: true,
    });

    socket.on("connect", () => {
      socket.emit("join", userId); // Join room with userId
    });

    // socket.on("disconnect", () => {});
  }
};

export const getSocket = () => socket;
