import { Button, Row, Select, Tooltip } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";
import Table from "antd/es/table";
import DataContext from "../../context/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { DownloadOutlined } from "@ant-design/icons";
import { SearchInput } from "../../components/functional";
import { CustomerProps, ProjectProps } from "../../interfaces/interfaces";
import { TourProps } from "antd/lib";
import { exportCustomerFile } from "../../utils/func/ExportFile";
import { CustomerDrawer, CustomerTable } from "../../components/customer";

export const CustomerListByProject = () => {
  const { idClient, idProject } = useParams();
  const navigate = useNavigate();

  const { clientInfo, isDemoEnv } = useContext(AuthContext);
  const {
    getData: getListData,
    customerByProject,
    projectList,
    getCustomersListByProject,
    handleTour,
  } = useContext(DataContext);
  const [selectedRowsToEdit, setSelectedRowToEdit] = useState<React.Key[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [customerDrawerStatus, setCustomerDrawerStatus] = useState<{
    open: boolean;
    idCustomer: string | null;
  }>({ open: false, idCustomer: "" });
  const [searchText, setSearchText] = useState<string>("");
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);

  useEffect(() => {
    if (idClient) {
      getListData("customer", idClient);
      getListData("projects", idClient, undefined, { active: true });
      getListData("users", idClient);
      getListData("project-templates", idClient, undefined, { active: true });
    }
  }, [idClient]);

  useEffect(() => {
    idClient && idProject && getCustomersListByProject({ idClient, idProject });
  }, [idClient, idProject]);

  useEffect(() => {
    setSearchQuery("");
    if (isDemoEnv) {
      let tourOpened = localStorage.getItem("tour-summary");
      if (!tourOpened) {
        handleTour(true);
        localStorage.setItem("tour-customer", "true");
      }
    }
  }, []);

  const filteredCustomers = customerByProject.filter((customer) =>
    customer.DisplayName.toLowerCase().includes(searchText.toLowerCase())
  );

  const onChangeSelectedRowsToEdit = (newSelectedRowToEdit: React.Key[]) => {
    setSelectedRowToEdit(newSelectedRowToEdit);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowsToEdit,
    onChange: onChangeSelectedRowsToEdit,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const steps: TourProps["steps"] = [
    {
      title: "Toolbar",
      description:
        "This is the toolbar, where you can find all the actions, filters, and export options.",
      target: () => ref1.current,
    },
    {
      title: "Edit Single/Multiple Customer(s)",
      description:
        "Edit the Reminder Settings for one or multiple Customers at the same time.",
      target: () => ref3.current,
    },

    {
      title: "Export Data",
      description: "Export the Customer list data to a CSV file.",
      target: () => ref5.current,
    },
    {
      title: "Create New Template",
      description: "Create a new Template for the Customer Reminder.",
      target: () => ref6.current,
    },
    {
      title: "See All templates",
      description: "See all the Templates created for the Customer Reminder.",
      target: () => ref7.current,
    },
    {
      title: "Filter By Customer Name",
      description: "Filter the Customer list by Customer Name.",
      target: () => ref8.current,
    },
  ];

  // const hasSelected = selectedRowsToEdit.length > 0;

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle
            title={clientInfo?.name + " - Customer Projects"}
            actions={[
              <div style={{ alignContent: "center" }}>
                <SearchInput
                  onSearch={(value: string) => {
                    if (value) {
                      setSearchQuery(value);
                      setSearchText(value);
                    } else {
                      setSearchQuery("");
                      setSearchText("");
                    }
                  }}
                />
              </div>,
              <div ref={ref8}>
                <Tooltip title="Filter By Project">
                  <Select
                    size="middle"
                    placeholder="Projects"
                    className="summary-client__card__actions__select"
                    options={projectList.map((project: ProjectProps) => ({
                      label: project.name,
                      value: project._id,
                    }))}
                    value={idProject}
                    onSelect={(value) => {
                      idClient &&
                        navigate(`/customer-project/${idClient}/${value}`);
                    }}
                  />
                </Tooltip>
              </div>,
              <></>,
              // <div ref={ref8}>
              //   <Tooltip title="Filter By Customer">
              //     <MultipleSelect
              //       placeholder="Customer"
              //       className="summary-client__card__actions__select"
              //       options={customerOptions}
              //       onSelect={(values) => {
              //         setCustomerSelected(values as any);
              //         let query = values.length > 0 ? values : null;
              //         console.log(query);
              //         if (query) {
              //           idClient &&
              //             idProject &&
              //             getCustomersListByProject({
              //               idClient,
              //               idProject,
              //               filters: {
              //                 customer: query,
              //               },
              //             });
              //         } else {
              //           idClient &&
              //             idProject &&
              //             getCustomersListByProject({
              //               idClient,
              //               idProject,
              //             });
              //         }
              //       }}
              //     />
              //   </Tooltip>
              // </div>,
              <div ref={ref5}>
                <Tooltip
                  title={
                    selectedRowsToEdit.length > 0
                      ? "Export Selected Data"
                      : "Export Data"
                  }
                  placement="left"
                >
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    size="large"
                    onClick={() => {
                      exportCustomerFile(
                        customerByProject,
                        selectedRowsToEdit as string[]
                      );
                    }}
                  />
                </Tooltip>
              </div>,
            ]}
          />
        </ColumnComponent>

        <ColumnComponent>
          <CustomerTable
            customers={filteredCustomers}
            onSelectOne={(record: CustomerProps) =>
              setCustomerDrawerStatus({ open: true, idCustomer: record.Id })
            }
            rowSelection={rowSelection}
          />
        </ColumnComponent>
      </Row>

      {idClient && (
        <CustomerDrawer
          open={customerDrawerStatus.open}
          idClient={idClient}
          idCustomer={customerDrawerStatus.idCustomer}
          onClose={() =>
            setCustomerDrawerStatus({ open: false, idCustomer: "" })
          }
          onSelectOne={(record: CustomerProps) =>
            setCustomerDrawerStatus({ open: true, idCustomer: record.Id })
          }
          projectPreselected={idProject}
        />
      )}
      {/* <Tour open={isTourOpen} onClose={() => handleTour(false)} steps={steps} /> */}
    </>
  );
};
