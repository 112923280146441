import { Button, Card, Space, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { ActivityProps, TagProps } from "../../interfaces/interfaces";
import dayjs from "dayjs";
import {
  EditOutlined,
  CopyOutlined,
  PhoneOutlined,
  MailOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  IssuesCloseOutlined,
  FormOutlined,
} from "@ant-design/icons";

export const ActivityList = ({
  activityList,
  onAddNewActivity,
  onEditActivity,
  onDuplicateActivity,
  idProject,
}: {
  activityList: ActivityProps[];
  onAddNewActivity: () => void;
  onEditActivity: (values: ActivityProps) => void;
  onDuplicateActivity: (values: ActivityProps) => void;
  idProject?: string | null;
}) => {
  const activityColumns: ColumnsType<ActivityProps> = [
    {
      title: "Actions",
      key: "edit",
      render: (params) => (
        <Space>
          <Tooltip title="Edit" placement="top">
            <Button
              onClick={() => onEditActivity(params)}
              icon={<EditOutlined />}
              size="small"
            ></Button>
          </Tooltip>
          <Tooltip title="Duplicate" placement="top">
            <Button
              onClick={() => onDuplicateActivity(params)}
              icon={<CopyOutlined />}
              size="small"
            ></Button>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "typeOfTask",
      key: "typeOfTask",
      render: (typeOfTask: ActivityProps["typeOfTask"]) =>
        typeOfTask === "Call" ? (
          <>
            <Tag color="lime" icon={<PhoneOutlined />}>
              Call
            </Tag>
          </>
        ) : typeOfTask === "Email" ? (
          <>
            <Tag color="cyan" icon={<MailOutlined />}>
              Email
            </Tag>
          </>
        ) : typeOfTask === "Follow-up" ? (
          <>
            <Tag color="magenta" icon={<CheckCircleOutlined />}>
              Follow-up
            </Tag>
          </>
        ) : typeOfTask === "Meeting" ? (
          <>
            <Tag color="geekblue" icon={<TeamOutlined />}>
              Meeting
            </Tag>
          </>
        ) : (
          <Tag color="orange" icon={<IssuesCloseOutlined />}>
            {typeOfTask}
          </Tag>
        ),
      sorter: (a, b) => a.typeOfTask.localeCompare(b.typeOfTask),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description) => (
        <div
          style={{ maxWidth: "100px", maxHeight: "100px", overflowY: "hidden" }}
        >
          {description}
        </div>
      ),
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Start",
      dataIndex: "startDate",
      className: "align-center",
      key: "startDate",
      render: (startDate: string) =>
        startDate ? dayjs(startDate).format("MM/DD/YYYY HH:mm") : "-",
      sorter: (a, b) =>
        (a.startDate
          ? dayjs(a.startDate).format("YYYY-MM-DD")
          : ""
        ).localeCompare(
          b.startDate ? dayjs(b.startDate).format("YYYY-MM-DD") : ""
        ),
    },
    {
      title: "End",
      dataIndex: "endDate",
      className: "align-center",
      key: "endDate",
      render: (endDate: string) =>
        endDate ? dayjs(endDate).format("MM/DD/YYYY HH:mm") : "-",
      sorter: (a, b) =>
        (a.endDate ? dayjs(a.endDate).format("YYYY-MM-DD") : "").localeCompare(
          b.endDate ? dayjs(b.endDate).format("YYYY-MM-DD") : ""
        ),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      className: "align-center",
      render: (_, row) => {
        if (!row.startDate || !row.endDate) return "-";
        const start = dayjs(row.startDate);
        const end = dayjs(row.endDate);
        const duration = dayjs.duration(end.diff(start));

        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        return days
          ? `${days}d ${hours}h ${minutes}m `
          : hours
            ? `${hours}h ${minutes}m `
            : minutes
              ? `${minutes}m`
              : "-";
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority: string) => (
        <Tag
          color={
            priority === "High"
              ? "red"
              : priority === "Medium"
                ? "orange"
                : "green"
          }
        >
          {priority}
        </Tag>
      ),
      sorter: (a, b) => a.priority.localeCompare(b.priority),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <Tag
          color={
            status === "Planned"
              ? "red"
              : status === "In Progress"
                ? "gold"
                : "green"
          }
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags: TagProps[]) => (
        <div
          style={{ maxWidth: "100px", maxHeight: "100px", overflowY: "hidden" }}
        >
          {tags.map((tag: TagProps) => (
            <Tag key={tag._id} color={tag.color}>
              {tag.name}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Assigned",
      dataIndex: "assignedTo",
      key: "assignedTo",
      render: (_, row) => <>{row.assignedTo?.name}</>,
      sorter: (a, b) =>
        (a.assignedTo?.name ? a.assignedTo?.name : "").localeCompare(
          b.assignedTo?.name ? b.assignedTo?.name : ""
        ),
    },
  ];
  return (
    <Card
      title="Activities"
      style={{ marginBottom: 16 }}
      extra={
        <Tooltip title="Add new activity" placement="top">
          <Button
            type="primary"
            onClick={onAddNewActivity}
            icon={<FormOutlined />}
            disabled={!idProject}
          ></Button>
        </Tooltip>
      }
    >
      <Table
        columns={activityColumns}
        dataSource={activityList}
        rowKey="_id"
        size="small"
        pagination={{ pageSize: 5 }}
      />
    </Card>
  );
};
