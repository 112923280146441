import { Button, Row, Table, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";
import DataContext from "../../context/DataContext";
import { useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { DownloadOutlined } from "@ant-design/icons";
import { MultipleSelect, SearchInput } from "../../components/functional";
import { CustomerProps } from "../../interfaces/interfaces";
import { TourProps } from "antd/lib";
import { exportCustomerFile } from "../../utils/func/ExportFile";
import { CustomerDrawer, CustomerTable } from "../../components/customer";
import QuickbooksContext from "../../context/QuickbooksContext";
const { Text } = Typography;

export const CustomerListPage = () => {
  const { idClient } = useParams();
  const { handleGetQbData, qbLoading } = useContext(QuickbooksContext);
  const { clientInfo, isDemoEnv, getClientInformation } =
    useContext(AuthContext);
  const {
    getData: getListData,
    customerOptions,
    customerByClient,
    handleTour,
    getCustomersListByClient,
  } = useContext(DataContext);
  const [selectedRowsToEdit, setSelectedRowToEdit] = useState<React.Key[]>([]);
  const [customerSelected, setCustomerSelected] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [customerDrawerStatus, setCustomerDrawerStatus] = useState<{
    open: boolean;
    idCustomer: string | null;
  }>({ open: false, idCustomer: "" });
  const [idProject, setIdProject] = useState<string | null>(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);

  useEffect(() => {
    if (idClient) {
      getListData("customer", idClient);
      getListData("projects", idClient, undefined, { active: true });
      getListData("users", idClient);
      getListData("project-templates", idClient, undefined, { active: true });
      getCustomersListByClient({ idClient });
      getClientInformation(idClient, () => {});
    }
  }, [idClient]);

  useEffect(() => {
    setSearchQuery("");
    if (isDemoEnv) {
      let tourOpened = localStorage.getItem("tour-summary");
      if (!tourOpened) {
        handleTour(true);
        localStorage.setItem("tour-customer", "true");
      }
    }
  }, []);

  const onChangeSelectedRowsToEdit = (newSelectedRowToEdit: React.Key[]) => {
    setSelectedRowToEdit(newSelectedRowToEdit);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowsToEdit,
    onChange: onChangeSelectedRowsToEdit,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const getLastUpdate = () => {
    return (
      <div>
        <Text>Last update on: </Text>
      </div>
    );
  };

  const steps: TourProps["steps"] = [
    {
      title: "Toolbar",
      description:
        "This is the toolbar, where you can find all the actions, filters, and export options.",
      target: () => ref1.current,
    },
    {
      title: "Edit Single/Multiple Customer(s)",
      description:
        "Edit the Reminder Settings for one or multiple Customers at the same time.",
      target: () => ref3.current,
    },

    {
      title: "Export Data",
      description: "Export the Customer list data to a CSV file.",
      target: () => ref5.current,
    },
    {
      title: "Create New Template",
      description: "Create a new Template for the Customer Reminder.",
      target: () => ref6.current,
    },
    {
      title: "See All templates",
      description: "See all the Templates created for the Customer Reminder.",
      target: () => ref7.current,
    },
    {
      title: "Filter By Customer Name",
      description: "Filter the Customer list by Customer Name.",
      target: () => ref8.current,
    },
  ];

  const hasSelected = selectedRowsToEdit.length > 0;

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle
            title={clientInfo?.name + " - Customer List"}
            extra={
              isDemoEnv
                ? []
                : [
                    // getLastUpdate(),
                    <div
                      style={{
                        textAlign: "center",
                        padding: "5px",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#2CA01C",
                          color: "#FFF",
                          borderColor: "#2CA01C",
                        }}
                        loading={qbLoading}
                        size="small"
                        onClick={() =>
                          idClient &&
                          handleGetQbData(idClient, () =>
                            getListData("projects", idClient)
                          )
                        }
                      >
                        Sync with QuickBooks
                      </Button>
                    </div>,
                  ]
            }
            actions={[
              <div>
                <SearchInput
                  onSearch={(value: string) => {
                    if (value) {
                      setSearchQuery(value);
                      idClient &&
                        getCustomersListByClient({
                          idClient,
                          search: value,
                        });
                    } else {
                      setSearchQuery("");
                      idClient &&
                        getCustomersListByClient({
                          idClient,
                        });
                    }
                  }}
                />
              </div>,
              <div>
                {/* <Tooltip title="Filter By Location">
                  <Select
                    className="summary-client__card__actions__select"
                    placeholder="Filter by Location"
                    options={clientInformation?.map((location) => ({
                      label: location.name,
                      value: location._id,
                    }))}
                    allowClear
                    size="middle"
                    // value={values.location?._id}
                    onChange={(newValue) => {
                      if (newValue) {
                        idClient &&
                          getCustomersListByClient({
                            idClient,
                            filters: { location: newValue, customer: null },
                          });
                      } else {
                        idClient &&
                          getCustomersListByClient({
                            idClient,
                          });
                      }
                    }}
                  ></Select>
                </Tooltip> */}
              </div>,

              <div ref={ref8}>
                <Tooltip title="Filter By Customer">
                  <MultipleSelect
                    placeholder="Customer"
                    className="summary-client__card__actions__select"
                    options={customerOptions}
                    onSelect={(values) => {
                      setCustomerSelected(values as any);
                      let query = values.length > 0 ? values : null;
                      idClient &&
                        getCustomersListByClient({
                          idClient,
                          filters: { customer: query },
                        });
                    }}
                  />
                </Tooltip>
              </div>,
              <div ref={ref5}>
                <Tooltip
                  title={
                    selectedRowsToEdit.length > 0
                      ? "Export Selected Data"
                      : "Export Data"
                  }
                  placement="left"
                >
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    size="large"
                    onClick={() => {
                      exportCustomerFile(
                        customerByClient,
                        selectedRowsToEdit as string[]
                      );
                    }}
                  />
                </Tooltip>
              </div>,
            ]}
          />
        </ColumnComponent>

        <ColumnComponent>
          <CustomerTable
            customers={customerByClient}
            onSelectOne={(record: CustomerProps, idProject?: string) => {
              setCustomerDrawerStatus({ open: true, idCustomer: record.Id });
              if (idProject) {
                setIdProject(idProject);
              } else {
                setIdProject(null);
              }
            }}
            rowSelection={rowSelection}
          />
        </ColumnComponent>
      </Row>
      {idClient && (
        <CustomerDrawer
          open={customerDrawerStatus.open}
          idClient={idClient}
          idCustomer={customerDrawerStatus.idCustomer}
          onSelectOne={(record: CustomerProps) =>
            setCustomerDrawerStatus({ open: true, idCustomer: record.Id })
          }
          onClose={() =>
            setCustomerDrawerStatus({ open: false, idCustomer: "" })
          }
          projectPreselected={idProject}
        />
      )}
    </>
  );
};
