import React from "react";
import { Notification } from "../interfaces/types/types";

type NotificationType = "success" | "error" | "info" | "warning";

interface NotificationContextType {
  notifications: Notification[];
  clearNotifications: (id?: string) => void;
  showNotification: (
    type: NotificationType,
    title: string,
    message: string,
    duration?: number
  ) => void;
  fetchNotifications: () => void;
}

const NotificationContext = React.createContext<NotificationContextType>({
  notifications: [],
  clearNotifications: (id?: string) => {},
  showNotification: () => {},
  fetchNotifications: () => {},
});

export default NotificationContext;
