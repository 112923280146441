import React from "react";
import {
  AdminClient,
  Login,
  ReportPage,
  AdminPage,
  HelpPage,
  TermsOfService,
  NotFundPage,
  PrivacyPolicy,
  PresentationPage,
  AccountMiddleware,
  ChangePasswordMiddleware,
  RevokeMiddleware,
  LaunchMiddleware,
  DisconnectMiddleware,
  UserListPage,
  CustomerListPage,
  ProjectListPage,
  ProjectTemplateListPage,
  CustomerListByProject,
  DashboardClient,
  DashboardPage,
} from "../../pages";
import { Navigate } from "react-router-dom";

const routes = {
  initial: "/",
  about: "/about",
  account: "/accounts/:idUser/:realmId",
  revoke: "/revoke",
  disconnect: "/disconnect/:idUser",
  launch: "/launch",
  accountPassword: "/account-auth/:idClient",
  // companySettings: "/company/:idClient",
  login: "/login",
  admin: "/admin",
  userList: "/users/:idClient",
  // quickbooksPage: "/quickbooks",
  // quickbooks: "/quickbooks/:idClient",
  adminClient: "/admin/:idClient",
  // settings: "/settings/:idClient",
  dashboard: "/dashboard/:idClient",
  customerListPage: "/customer/:idClient",
  projectListPage: "/project/:idClient",
  projectTemplateListPage: "/project-template/:idClient",
  customerByProject: "/customer-project/:idClient/:idProject",
  help: "/help",
  report: "/report",
  terms: "/terms",
  privacy: "/privacy",
  notFound: "/*",
};

export const AppPages = [
  {
    path: routes.initial,
    protected: false,
    component: <Navigate to={routes.login} replace />,
  },
  { path: routes.login, protected: false, component: <Login /> },
  {
    path: routes.account,
    isMiddleware: true,
    component: <AccountMiddleware />,
  },
  {
    path: routes.launch,
    protected: true,
    isProtectedMiddleware: true,
    component: <LaunchMiddleware />,
  },
  {
    path: routes.launch,
    isMiddleware: true,
    component: <LaunchMiddleware />,
  },
  {
    path: routes.revoke,
    protected: true,
    isProtectedMiddleware: true,
    component: <RevokeMiddleware />,
    role: "ADMIN",
  },
  {
    path: routes.revoke,
    isMiddleware: true,
    component: <RevokeMiddleware />,
  },
  {
    path: routes.disconnect,
    protected: true,
    isProtectedMiddleware: true,
    component: <DisconnectMiddleware />,
    role: "ADMIN",
  },
  {
    path: routes.disconnect,
    isMiddleware: true,
    component: <DisconnectMiddleware />,
    role: "ADMIN",
  },

  {
    path: routes.accountPassword,
    isMiddleware: true,
    component: <ChangePasswordMiddleware />,
  },

  {
    path: routes.userList,
    protected: true,
    component: <UserListPage />,
    role: "ADMIN",
  },
  { path: routes.about, protected: false, component: <PresentationPage /> },
  { path: routes.terms, isMiddleware: true, component: <TermsOfService /> },
  { path: routes.privacy, isMiddleware: true, component: <PrivacyPolicy /> },
  { path: routes.report, protected: true, component: <ReportPage /> },
  {
    path: routes.admin,
    protected: true,
    component: <AdminPage />,
    role: "ADMIN",
  },

  { path: routes.adminClient, protected: true, component: <AdminClient /> },

  {
    path: routes.customerListPage,
    protected: true,
    component: <CustomerListPage />,
  },
  {
    path: routes.customerByProject,
    protected: true,
    component: <CustomerListByProject />,
  },
  {
    path: routes.projectListPage,
    protected: true,
    component: <ProjectListPage />,
  },
  {
    path: routes.projectTemplateListPage,
    protected: true,
    component: <ProjectTemplateListPage />,
  },
  {
    path: routes.dashboard,
    protected: true,
    component: <DashboardClient />,
  },
  // {
  //   path: routes.customerPage,
  //   protected: true,
  //   component: <CustomerPage />,
  // },
  // {
  //   path: routes.templatePage,
  //   protected: true,
  //   component: <TemplatePage />,
  // },
  {
    path: routes.dashboard,
    protected: true,
    component: <DashboardPage />,
  },
  // { path: routes.summaryClient, protected: true, component: <SummaryClient /> },
  // { path: routes.summary, protected: true, component: <SummaryPage /> },
  // {
  //   path: routes.detaiClient,
  //   protected: true,
  //   component: <DetailClient />,
  // },
  // {
  //   path: routes.detail,
  //   protected: true,
  //   component: <DetailPage />,
  // },
  // { path: routes.settings, protected: true, component: <Settings /> },
  { path: routes.help, protected: true, component: <HelpPage /> },
  { path: routes.report, protected: true, component: <ReportPage /> },
  { path: routes.notFound, protected: true, component: <NotFundPage /> },
  { path: routes.notFound, protected: false, component: <NotFundPage /> },
];
