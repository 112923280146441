import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Checkbox, Popconfirm, Row, Table, Tooltip } from "antd";
import { RoleProps, UserProps } from "../../interfaces/interfaces";
import { CardComponent, ColumnComponent } from "../../components/ui";
import { SearchInput } from "../../components/functional";
import {
  DownloadOutlined,
  UserAddOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { ModalUserForm } from "../../components/user";
import { MODAL_INITIAL_STATE, USER_INITIAL_STATE } from "../../utils/data";
import DataContext from "../../context/DataContext";
import AuthContext from "../../context/AuthContext";
import { useDemoModal } from "../../hooks";
import dayjs from "dayjs";
import { exportUserList } from "../../utils/func/ExportFile";

export const UserListPage = () => {
  const { idClient } = useParams();
  const { getData, handleEditData, handleCreateData, usersList } =
    useContext(DataContext);
  const { isDemoEnv, profileInfo } = useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [modalStatus, setModalStatus] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    multiple: boolean;
    payload?: UserProps | null;
  }>(MODAL_INITIAL_STATE);

  useEffect(() => {
    if (idClient) {
      getData("users", idClient);
    }
  }, [idClient]);

  const createOrUpdateTemplate = (
    values: UserProps,
    idClient: string,
    idUser?: string
  ) => {
    if (modalStatus.create) {
      handleCreateData({
        endpoint: "user",
        idClient,
        data: values,
        next: () => getData("users", idClient),
      });
    } else if (modalStatus.update && idUser) {
      handleEditData({
        endpoint: `user/${idClient}`,
        newData: { ...values, _id: idUser },
        next: () => getData("users", idClient),
      });
    }
  };

  const columns: ColumnsType<UserProps> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      onCell: (record, index) => {
        return {
          onClick: () =>
            setModalStatus({
              open: true,
              create: false,
              update: true,
              multiple: false,
              payload: record,
            }),
        };
      },
    },
    {
      title: "Email",
      dataIndex: "username",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role: RoleProps) => role?.description,
    },
    {
      title: "Last Login",
      minWidth: 50,
      dataIndex: "lastLogin",
      key: "lastLogin",
      render: (lastLogin: Date) =>
        lastLogin ? dayjs(lastLogin).format("MM/DD/YYYY HH:mm") : "",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "Active",
      className: "table-row align-center",
      render: (_, { active, username, _id }) => (
        <Popconfirm
          disabled={username === profileInfo?.username}
          title={
            active
              ? "This action will deactivate the user. Are you sure?"
              : "This action will activate the user. Are you sure?"
          }
          onConfirm={() => {
            if (isDemoEnv) {
              openModalDemo();
            } else {
              idClient &&
                handleEditData({
                  endpoint: `user/${idClient}`,
                  newData: { active: !active, _id },
                  next: () => getData("users", idClient),
                });
            }
          }}
        >
          <Checkbox
            checked={active}
            disabled={username === profileInfo?.username}
          />
        </Popconfirm>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      className: "table-row align-center",
      render: (_, user) => (
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() =>
            setModalStatus({
              open: true,
              create: false,
              update: true,
              multiple: false,
              payload: user,
            })
          }
        ></Button>
      ),
      onCell: (record, index) => {
        return {
          onClick: () =>
            setModalStatus({
              open: true,
              create: false,
              update: true,
              multiple: false,
              payload: record,
            }),
        };
      },
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      key: "Delete",
      className: "table-row align-center",
      render: (_, { _id, username }) => (
        <>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              if (isDemoEnv) {
                openModalDemo();
              } else {
                idClient &&
                  handleEditData({
                    endpoint: `user/${idClient}`,
                    newData: { deleteFromClient: true, _id },
                    next: () => getData("users", idClient),
                  });
              }
            }}
          >
            <Tooltip title="Remove User" placement="left">
              <Button
                icon={<DeleteOutlined />}
                disabled={username === profileInfo?.username}
                type="link"
              />
            </Tooltip>
          </Popconfirm>
        </>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <div>
            <CardComponent
              className="summary-client__card"
              title={<>Users</>}
              actions={[
                <>
                  <SearchInput
                    onSearch={(value: string) => {
                      if (value) {
                        idClient && getData("users", idClient, value);
                      } else {
                        idClient && getData("users", idClient);
                      }
                    }}
                  />
                </>,
                <div>
                  <Tooltip title="Create new User" placement="left">
                    <Button
                      type="link"
                      icon={<UserAddOutlined />}
                      size="large"
                      onClick={() => {
                        setModalStatus({
                          open: true,
                          create: true,
                          update: false,
                          multiple: false,
                          payload: USER_INITIAL_STATE,
                        });
                      }}
                    />
                  </Tooltip>
                </div>,
                <div>
                  <Tooltip title={"Export"} placement="left">
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      size="large"
                      onClick={() => exportUserList(usersList, [])}
                    />
                  </Tooltip>
                </div>,
              ]}
            ></CardComponent>
          </div>
        </ColumnComponent>
        <ColumnComponent>
          <Table
            bordered
            size="small"
            columns={columns}
            dataSource={usersList}
            rowKey="_id"
            scroll={{ y: "70vh", x: "100%" }}
            tableLayout="auto"
          />
        </ColumnComponent>
      </Row>
      <ModalUserForm
        modalStatus={modalStatus}
        onCancel={() => setModalStatus(MODAL_INITIAL_STATE)}
        onSubmit={(values) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            if (idClient) {
              createOrUpdateTemplate(
                values,
                idClient,
                modalStatus.payload?._id
              );
              setModalStatus(MODAL_INITIAL_STATE);
            }
          }
        }}
      />
      <DemoModal />
    </>
  );
};
