import { Form, Input, Button, Modal, Row, Col, Select, DatePicker } from "antd";
import { ContactProps } from "../../../interfaces/interfaces";
import { useEffect, useState } from "react";
import { CONTACT_INITIAL_STATE } from "../../../utils/data";
import dayjs from "dayjs";

interface Props {
  modalStatus: {
    open: boolean;
    payload?: ContactProps | null;
  } | null;
  onCancel: () => void;
  onSubmit: (values: ContactProps) => void;
}
const titleOptions = [
  { label: "Mr.", value: "Mr" },
  { label: "Mrs.", value: "Mrs" },
  { label: "Ms.", value: "Ms" },
  { label: "Dr.", value: "Dr" },
];

const relationOptions = [
  { label: "Owner", value: "Owner" },
  { label: "Primary Contact", value: "Primary Contact" },
  { label: "Additional Contact", value: "Additional Contact" },
  { label: "Fiduciary", value: "Fiduciary" },
  { label: "Trustee", value: "Trustee" },
  { label: "Parent", value: "Parent" },
  { label: "Child", value: "Child" },
  { label: "Spouse / Partner", value: "Spouse / Partner" },
  { label: "Other", value: "Other" },
];

export const ModalCreateOrUpdateContact = ({
  modalStatus,
  onCancel,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState<ContactProps>(CONTACT_INITIAL_STATE);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(CONTACT_INITIAL_STATE);
      setValues(CONTACT_INITIAL_STATE);
    }
  }, [values]);

  useEffect(() => {
    if (modalStatus && modalStatus.payload) {
      setValues({ ...modalStatus.payload });
    } else {
      setValues(CONTACT_INITIAL_STATE);
    }
  }, [modalStatus]);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      title={modalStatus?.payload ? "Update Contact" : "New Contact"}
      open={modalStatus?.open || false}
      centered
      width={800}
      onOk={() => {
        onCancel();
        form.resetFields();
        setValues(CONTACT_INITIAL_STATE);
      }}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setValues(CONTACT_INITIAL_STATE);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={() => {
          onSubmit(values);
        }}
        form={form}
      >
        <Row gutter={24}>
          {/* Left Column */}
          <Col span={12}>
            <Form.Item label="Title" name="title">
              <Select
                options={titleOptions}
                value={values.title}
                placeholder="Select title"
                onChange={(value) => setValues({ ...values, title: value })}
              />
            </Form.Item>

            <Form.Item
              label="Full Name"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input
                placeholder="Enter full name"
                onChange={(event) =>
                  setValues({ ...values, name: event.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Relationship" name="relationship">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                options={relationOptions}
                placeholder="Select Relationship"
                value={values.relationship}
                onChange={(value) =>
                  setValues({ ...values, relationship: value })
                }
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Email is required" },
                { type: "email", message: "Enter a valid email" },
              ]}
            >
              <Input
                placeholder="Enter email"
                onChange={(event) =>
                  setValues({ ...values, email: event.target.value })
                }
              />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                { required: true, message: "Phone number is required" },
                {
                  pattern: /^[0-9\s+()-]+$/,
                  message: "Enter a valid phone number",
                },
              ]}
            >
              <Input
                placeholder="Enter phone number"
                onChange={(event) =>
                  setValues({ ...values, phone: event.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Birthday">
              <DatePicker
                name="LastContact"
                format={"MM/DD"}
                value={values.birthday && dayjs(values.birthday)}
                className="collections-form__form-item__date-picker"
                allowClear={false}
                onChange={(date) => {
                  setValues({
                    ...values,
                    birthday: date,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Street Address" name="address">
              <Input
                placeholder="Enter street address"
                onChange={(event) =>
                  setValues({ ...values, address: event.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Input
                placeholder="Enter city"
                onChange={(event) =>
                  setValues({ ...values, city: event.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="State" name="state">
              <Input
                placeholder="Enter state"
                onChange={(event) =>
                  setValues({ ...values, state: event.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="ZIP Code" name="zip">
              <Input
                placeholder="Enter ZIP code"
                onChange={(event) =>
                  setValues({ ...values, zip: event.target.value })
                }
              />
            </Form.Item>
          </Col>

          {/* Right Column */}
        </Row>

        <Form.Item label="Additional Notes" name="notes">
          <Input.TextArea
            placeholder="Enter any additional notes"
            rows={3}
            onChange={(event) =>
              setValues({ ...values, notes: event.target.value })
            }
          />
        </Form.Item>

        {/* Buttons */}
        <Row justify="end">
          <Col>
            <Button
              type="default"
              onClick={onCancel}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {modalStatus?.payload ? "Update Contact" : "Create Contact"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
