import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  DatePicker,
  Switch,
  InputNumber,
  message,
  Tooltip,
  Divider,
  Space,
  InputRef,
  Drawer,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { PROJECT_INITIAL_STATE } from "../../utils/data";
import {
  ProjectProps,
  ProjectTemplateProps,
  UserProps,
} from "../../interfaces/interfaces";
import { CustomerListWithNoDetails } from "../customer";
import dayjs from "dayjs";
import DataContext from "../../context/DataContext";
import AuthContext from "../../context/AuthContext";
import { PlusOutlined } from "@ant-design/icons";
import { TagSelector } from "../ui";

interface Props {
  modalStatus: {
    open: boolean;
    create: boolean;
    update: boolean;
    payload?: ProjectProps | null;
  } | null;
  handleCreateLocation: (locationName: string) => void;
  handleCreateNewProjectStatus: (projectStatusName: string) => void;
  onCancel: () => void;
  onSubmit: (values: ProjectProps) => void;
}

const fieldTypes = [
  { value: "String", label: "String" },
  { value: "Number", label: "Number" },
  { value: "Date", label: "Date" },
  { value: "Boolean", label: "Boolean" },
];

export const DrawerCreateOrUpdateProject = ({
  modalStatus,
  handleCreateLocation,
  handleCreateNewProjectStatus,
  onCancel,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState<ProjectProps>(PROJECT_INITIAL_STATE);
  const [locationName, setLocationName] = useState("");
  const [projectStatusName, setProjectStatusName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const inputRefStatus = useRef<InputRef>(null);
  const { usersList, projectTemplateList } = useContext(DataContext);
  const { clientInformation } = useContext(AuthContext);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(PROJECT_INITIAL_STATE);
      setValues(PROJECT_INITIAL_STATE);
    }
  }, [values]);

  useEffect(() => {
    if (modalStatus?.payload) {
      setValues(modalStatus.payload);
    } else {
      setValues(PROJECT_INITIAL_STATE);
    }
  }, [modalStatus]);

  const createLocation = async () => {
    if (!locationName.trim()) {
      message.error("Please enter a location name.");
      return;
    }
    try {
      handleCreateLocation(locationName);
      setLocationName("");
    } catch (error) {
      message.error("Failed to create. Please try again.");
    }
  };
  const createProjectStatus = async () => {
    if (!projectStatusName.trim()) {
      message.error("Please enter a location name.");
      return;
    }
    try {
      handleCreateNewProjectStatus(projectStatusName);
      setProjectStatusName("");
    } catch (error) {
      message.error("Failed to create. Please try again.");
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Drawer
        title="Project"
        open={modalStatus?.open}
        onClose={onCancel}
        footer={
          <>
            <Button type="default" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!values.projectTemplate?._id}
              style={{ marginLeft: 8 }}
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    onSubmit(values);
                    form.setFieldsValue(PROJECT_INITIAL_STATE);
                    setValues(PROJECT_INITIAL_STATE);
                  })
                  .catch((error) => {
                    console.error("Validation failed:", error);
                  });
              }}
            >
              Save Project
            </Button>
          </>
        }
        extra={
          <>
            <Button type="default" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!values.projectTemplate?._id}
              style={{ marginLeft: 8 }}
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    onSubmit(values);
                    form.setFieldsValue(PROJECT_INITIAL_STATE);
                    setValues(PROJECT_INITIAL_STATE);
                  })
                  .catch((error) => {
                    console.error("Validation failed:", error);
                  });
              }}
            >
              Save Project
            </Button>
          </>
        }
        size="large"
        width={"80vw"}
      >
        <Row gutter={16}>
          <Col span={16}>
            <Form layout="vertical" form={form} initialValues={values}>
              <Row gutter={16}>
                {/* Project Template */}
                <Col span={8}>
                  <Form.Item label="Template">
                    <Select
                      showSearch
                      filterOption={filterOption}
                      value={values.projectTemplate?._id}
                      placeholder="Select Template"
                      style={{ width: "100%" }}
                      options={projectTemplateList.map((projectTemplate) => ({
                        label: projectTemplate.name,
                        value: projectTemplate._id,
                      }))}
                      onChange={(value) => {
                        const selected = projectTemplateList.find(
                          (projectTemplate) => projectTemplate._id === value
                        );
                        setValues((prev) => ({
                          ...prev,
                          projectTemplate: selected as ProjectTemplateProps,
                          startDate: !prev.startDate
                            ? selected?.startDate || null
                            : prev.startDate,
                          endDate: !prev.endDate
                            ? selected?.endDate || null
                            : prev.endDate,
                          description: !prev.description
                            ? selected?.description || ""
                            : prev.description,
                        }));
                      }}
                    />
                  </Form.Item>
                </Col>
                {/* Project Name */}
                <Col span={8}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: "Project name is required" },
                    ]}
                  >
                    <Input
                      placeholder="Enter project name"
                      value={values.name}
                      onChange={(event) =>
                        setValues((prev) => ({
                          ...prev,
                          name: event.target.value,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Tags">
                    <TagSelector
                      selectedTags={values.tags || []}
                      setSelectedTags={(selectedTags) => {
                        setValues({ ...values, tags: selectedTags });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Status">
                    <Select
                      placeholder="Select Status"
                      options={clientInformation?.projectStatus?.map(
                        (status) => ({
                          label: status.name,
                          value: status._id,
                        })
                      )}
                      value={values.status?._id}
                      onChange={(newValue) => {
                        let selected = clientInformation?.projectStatus?.find(
                          (status) => status._id === newValue
                        );
                        setValues((prev) => ({
                          ...prev,
                          status: selected as any,
                        }));
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space style={{ padding: "0 8px 4px" }}>
                            <Input
                              placeholder="New Status"
                              ref={inputRefStatus}
                              value={projectStatusName}
                              onChange={(event) =>
                                setProjectStatusName(event.target.value)
                              }
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Tooltip title="Add New Status">
                              <Button
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={createProjectStatus}
                              ></Button>
                            </Tooltip>
                          </Space>
                        </>
                      )}
                    ></Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Location">
                    <Select
                      placeholder="Select Location"
                      options={clientInformation?.locations?.map(
                        (location) => ({
                          label: location.name,
                          value: location._id,
                        })
                      )}
                      value={values.location?._id}
                      onChange={(newValue) => {
                        let selected = clientInformation?.locations?.find(
                          (location) => location._id === newValue
                        );
                        setValues((prev) => ({
                          ...prev,
                          location: selected as any,
                        }));
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space style={{ padding: "0 8px 4px" }}>
                            <Input
                              placeholder="New location"
                              ref={inputRef}
                              value={locationName}
                              onChange={(event) =>
                                setLocationName(event.target.value)
                              }
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Tooltip title="Add New Location">
                              <Button
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={createLocation}
                              ></Button>
                            </Tooltip>
                          </Space>
                        </>
                      )}
                    ></Select>
                  </Form.Item>
                </Col>
                {/* Assigned To */}
                <Col span={8}>
                  <Form.Item label="Assigned To">
                    <Select
                      showSearch
                      filterOption={filterOption}
                      value={values.assignedTo?._id}
                      placeholder="Select user"
                      style={{ width: "100%" }}
                      options={usersList.map((user) => ({
                        label: user.name,
                        value: user._id,
                      }))}
                      onChange={(value) => {
                        const selected = usersList.find(
                          (user) => user._id === value
                        );
                        setValues({
                          ...values,
                          assignedTo: selected as UserProps,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                {/* Start Date */}
                <Col span={11}>
                  <Form.Item label="Start Date">
                    <DatePicker
                      format="MM/DD/YYYY"
                      style={{ width: "100%" }}
                      value={values.startDate && dayjs(values.startDate)}
                      onChange={(date) =>
                        setValues((prev) => ({
                          ...prev,
                          startDate: date as any,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>

                {/* End Date */}
                <Col span={11}>
                  <Form.Item label="End Date">
                    <DatePicker
                      format="MM/DD/YYYY"
                      style={{ width: "100%" }}
                      value={values.endDate && dayjs(values.endDate)}
                      onChange={(date) =>
                        setValues((prev) => ({ ...prev, endDate: date as any }))
                      }
                    />
                  </Form.Item>
                </Col>
                {/* Active Switch */}
                <Col span={2}>
                  <Form.Item
                    label="Active"
                    name="active"
                    valuePropName="checked"
                  >
                    <Switch
                      value={values.active}
                      onChange={(checked) =>
                        setValues((prev) => ({ ...prev, active: checked }))
                      }
                    />
                  </Form.Item>
                </Col>

                {/* Description */}
                <Col span={24}>
                  <Form.Item label="Description" name="description">
                    <Input.TextArea
                      rows={6}
                      placeholder="Enter project description"
                      value={values.description}
                      onChange={(event) =>
                        setValues((prev) => ({
                          ...prev,
                          description: event.target.value,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Dynamic Custom Fields */}
              <Form.List name="customFields">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={16} key={key} align="middle">
                        {/* Field Name */}
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Field name is required",
                              },
                            ]}
                          >
                            <Input placeholder="Field Name" />
                          </Form.Item>
                        </Col>

                        {/* Field Type */}
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            name={[name, "type"]}
                            rules={[
                              { required: true, message: "Select a type" },
                            ]}
                          >
                            <Select
                              placeholder="Field Type"
                              options={fieldTypes}
                            ></Select>
                          </Form.Item>
                        </Col>

                        {/* Field Value (Dynamic Based on Type) */}
                        <Col span={8}>
                          <Form.Item shouldUpdate>
                            {({ getFieldValue }) => {
                              const fieldType = getFieldValue([
                                "customFields",
                                name,
                                "type",
                              ]);

                              if (fieldType === "String") {
                                return (
                                  <Form.Item
                                    {...restField}
                                    name={[name, "value"]}
                                  >
                                    <Input placeholder="Enter text" />
                                  </Form.Item>
                                );
                              }

                              if (fieldType === "Boolean") {
                                return (
                                  <Form.Item
                                    {...restField}
                                    name={[name, "value"]}
                                    valuePropName="checked"
                                  >
                                    <Switch />
                                  </Form.Item>
                                );
                              }

                              if (fieldType === "Date") {
                                return (
                                  <Form.Item
                                    {...restField}
                                    name={[name, "value"]}
                                  >
                                    <DatePicker style={{ width: "100%" }} />
                                  </Form.Item>
                                );
                              }

                              if (fieldType === "Number") {
                                return (
                                  <Form.Item
                                    {...restField}
                                    name={[name, "value"]}
                                  >
                                    <InputNumber style={{ width: "100%" }} />
                                  </Form.Item>
                                );
                              }

                              return null;
                            }}
                          </Form.Item>
                        </Col>

                        {/* Remove Field Button */}
                        <Col span={4}>
                          <Button
                            type="link"
                            danger
                            onClick={() => remove(name)}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    ))}

                    {/* Add Custom Field Button */}
                    {/* <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    + Add Custom Field
                  </Button>
                </Form.Item> */}
                  </>
                )}
              </Form.List>
            </Form>
          </Col>
          <Col span={8}>
            <CustomerListWithNoDetails
              customersSelected={values.customers}
              onSave={(customersSelected: any) => {
                setValues((prev) => ({
                  ...prev,
                  customers: customersSelected,
                }));
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};
