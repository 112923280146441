import React from "react";
import {
  ClientProps,
  CustomerProps,
  FilterProps,
  InvoiceProps,
  ListComponentProps,
  UserProps,
  QuickbooksCompanyProps,
  StatusProps,
  TemplateProps,
  ProjectProps,
  TaskProps,
  ActivityProps,
  ContactProps,
  ToDoProps,
  ProjectTemplateProps,
  TagProps,
} from "../interfaces/interfaces";

interface Props {
  dataLoading: boolean;
  dataSuccess: { status: boolean; message: string };
  dataError: { status: boolean; message: string };
  dataWarning: {
    status: boolean;
    message: string;
    actionLabel?: string;
    action?: () => void;
  };
  statusList: StatusProps[];
  invoiceList: InvoiceProps[];
  customerOptions: ListComponentProps[];
  statusOptions: ListComponentProps[];
  isModalStatusOpen: boolean;
  statusToEdit: StatusProps | null;
  client: null | ClientProps;
  isTourOpen: boolean;
  userAccount: any;
  termOptions: ListComponentProps[];
  customerByClient: CustomerProps[];
  customerByProject: CustomerProps[];
  templateList: TemplateProps[];
  usersList: UserProps[];
  projectList: ProjectProps[];
  projectTemplateList: ProjectTemplateProps[];
  taskList: TaskProps[];
  activityList: ActivityProps[];
  contactList: ContactProps[];
  todoList: ToDoProps[];
  tagList: TagProps[];
  taskModal: {
    open: boolean;
    create: boolean;
    update: boolean;
    payload: TaskProps | null;
  } | null;
  projectModal: {
    open: boolean;
    create: boolean;
    update: boolean;
    payload: ProjectProps | null;
  } | null;
  activityModal: {
    open: boolean;
    create: boolean;
    update: boolean;
    payload: ActivityProps | null;
  } | null;
  contactModal: {
    open: boolean;
    create: boolean;
    update: boolean;
    payload: ContactProps | null;
  } | null;
  projectSelected: string | null;
  handleChangeProjectSelected: (value: string | null) => void;
  handleTour: (status: boolean) => void;
  openCloseModalStatus: (value: boolean) => void;
  handleWarning: (
    status: boolean,
    message: string,
    actionLabel?: string,
    action?: () => void
  ) => void;
  handleSuccess: (status: boolean, message: string) => void;
  selectStatusToEdit: (value: StatusProps | null) => void;
  resetContacts: () => void;
  getData: (
    endpoint:
      | "status"
      | "customer"
      | "invoice"
      | "deposit"
      | "credit"
      | "payment"
      | "journal"
      | "client"
      | "user-account"
      | "profile-info"
      | "term"
      | "template"
      | "users"
      | "projects"
      | "project-templates"
      | "activities"
      | "tasks"
      | "contacts"
      | "todos"
      | "tags",
    idClient: string,
    search?: string,
    filters?: any,
    showAlert?: boolean
  ) => void;
  getReportData: (
    endpoint: "report" | "logs",
    idClient: string,
    filters: FilterProps,
    next?: (response: any) => void
  ) => void;
  handleCreateData: ({
    endpoint,
    idClient,
    data,
    next,
    showAlert,
  }: {
    endpoint: string;
    idClient: string;
    data: any;
    next?: (newData?: any) => void;
    showAlert?: boolean;
  }) => void;
  handleEditData: ({
    endpoint,
    newData,
    next,
    nextOnFailed,
    showAlert,
  }: {
    endpoint: string;
    newData: any;
    next?: () => void;
    nextOnFailed?: (e: any) => void;
    showAlert?: boolean;
  }) => void;
  handleBulkEdit: ({
    endpoint,
    items,
    newData,
    next,
  }: {
    endpoint: string;
    items: string[];
    newData: any;
    next?: () => void;
  }) => void;
  handleDeleteData: ({
    endpoint,
    next,
  }: {
    endpoint: string;
    next: () => void;
  }) => void;
  handleBulkDeleteData: (
    endpoint:
      | "status"
      | "invoice"
      | "payment"
      | "deposit"
      | "credit"
      | "journal",
    idClient: string,
    idList: string[]
  ) => void;
  fetchUserAccounts: (idUser: string) => void;
  getQbCompanyInfo: (
    realmId: string,
    next: (data: QuickbooksCompanyProps) => void
  ) => void;
  getCustomersListByClient: ({
    idClient,
    filters,
    search,
    next,
  }: {
    idClient: string;
    filters?: { customer: string[] | null; location?: string };
    search?: string | null;
    next?: () => void;
  }) => void;
  getCustomersListByProject: ({
    idClient,
    idProject,
    filters,
    search,
    next,
  }: {
    idClient: string;
    idProject: string;
    filters?: { customer: string[] | null };
    search?: string | null;
    next?: () => void;
  }) => void;
  openItemModal: (
    type: "task" | "project" | "activity" | "contact",
    create: boolean,
    payload?: any,
    itemId?: string
  ) => void;
  closeItemModal: (type: "task" | "project" | "activity" | "contact") => void;
  createOrUpdateTask: (
    customerInfo: CustomerProps,
    values: TaskProps,
    idClient: string,
    idTask?: string
  ) => void;
  createOrUpdateActivity: (
    customerInfo: CustomerProps,
    values: ActivityProps,
    idClient: string,
    idActivity?: string
  ) => void;
  createOrUpdateContact: (
    customerInfo: CustomerProps,
    values: ContactProps,
    idClient: string
  ) => void;
}

const DataContext = React.createContext<Props>({
  dataLoading: false,
  dataSuccess: { status: false, message: "" },
  dataError: { status: false, message: "" },
  dataWarning: {
    status: false,
    message: "",
    actionLabel: "",
    action: () => {},
  },
  statusList: [],
  customerOptions: [],
  statusOptions: [],
  invoiceList: [],
  isModalStatusOpen: false,
  statusToEdit: null,
  client: null,
  isTourOpen: false,
  userAccount: [],
  termOptions: [],
  customerByClient: [],
  customerByProject: [],
  templateList: [],
  usersList: [],
  projectList: [],
  projectTemplateList: [],
  taskList: [],
  activityList: [],
  contactList: [],
  todoList: [],
  tagList: [],
  taskModal: null,
  projectModal: null,
  activityModal: null,
  contactModal: null,
  projectSelected: null,
  handleChangeProjectSelected: () => {},
  handleTour: () => {},
  openCloseModalStatus: () => {},
  handleWarning: () => {},
  selectStatusToEdit: () => {},
  resetContacts: () => {},
  getData: () => {},
  getReportData: () => {},
  handleCreateData: () => {},
  handleEditData: () => {},
  handleBulkEdit: () => {},
  handleDeleteData: () => {},
  handleBulkDeleteData: () => {},
  handleSuccess: () => {},
  fetchUserAccounts: () => {},
  getQbCompanyInfo: () => {},
  getCustomersListByClient: () => {},
  getCustomersListByProject: () => {},
  openItemModal: () => {},
  closeItemModal: () => {},
  createOrUpdateTask: () => {},
  createOrUpdateActivity: () => {},
  createOrUpdateContact: () => {},
});

export default DataContext;
