import { Button, Row, Tabs, Tooltip, Tour } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CardComponent, CardTitle, ColumnComponent } from "../../components/ui";
import Table from "antd/es/table";
import DataContext from "../../context/DataContext";
import { useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { DownloadOutlined, FolderAddOutlined } from "@ant-design/icons";
import { SearchInput } from "../../components/functional";
import { ProjectTemplateProps } from "../../interfaces/interfaces";
import { useDemoModal } from "../../hooks";
import { exportProjectTemplateList } from "../../utils/func/ExportFile";
import {
  MODAL_INITIAL_STATE,
  PROJECT_TEMPLATE_INITIAL_STATE,
} from "../../utils/data";
import {
  ModalCreateOrUpdateProjectTemplate,
  ProjectTemplateList,
} from "../../components/project-template";
import { TabsProps } from "antd/lib";

export const ProjectTemplateListPage = () => {
  const { idClient } = useParams();
  const { clientInfo, isDemoEnv } = useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const {
    getData,
    projectTemplateList,
    handleTour,
    handleCreateData,
    handleEditData,
  } = useContext(DataContext);
  const [selectedRowsToEdit, setSelectedRowToEdit] = useState<React.Key[]>([]);
  const [modalStatus, setModalStatus] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    payload?: ProjectTemplateProps | null;
  }>(MODAL_INITIAL_STATE);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);

  useEffect(() => {
    if (idClient) {
      getData("project-templates", idClient, undefined, { active: true });
      getData("users", idClient);
    }
  }, [idClient]);

  useEffect(() => {
    if (isDemoEnv) {
      let tourOpened = localStorage.getItem("tour-summary");
      if (!tourOpened) {
        handleTour(true);
        localStorage.setItem("tour-customer", "true");
      }
    }
  }, []);

  const onChangeSelectedRowsToEdit = (newSelectedRowToEdit: React.Key[]) => {
    setSelectedRowToEdit(newSelectedRowToEdit);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowsToEdit,
    onChange: onChangeSelectedRowsToEdit,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const createOrUpdateProjectTemplate = (
    values: ProjectTemplateProps,
    idClient: string,
    idProjectTemplate?: string
  ) => {
    if (modalStatus.create) {
      handleCreateData({
        endpoint: "project-templates",
        idClient,
        data: values,
        next: () =>
          getData("project-templates", idClient, undefined, { active: true }),
      });
    } else if (modalStatus.update && idProjectTemplate) {
      handleEditData({
        endpoint: `project-template/${idClient}/${idProjectTemplate}`,
        newData: values,
        next: () =>
          getData("project-templates", idClient, undefined, { active: true }),
      });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Active",
      children: (
        <ProjectTemplateList
          projectTemplateList={projectTemplateList}
          onSelectOne={(project) => {
            setModalStatus({
              open: true,
              create: false,
              update: true,
              payload: project,
            });
          }}
          rowSelection={rowSelection}
        />
      ),
    },
    {
      key: "2",
      label: "Archived",
      children: (
        <ProjectTemplateList
          projectTemplateList={projectTemplateList}
          onSelectOne={(project) => {
            setModalStatus({
              open: true,
              create: false,
              update: true,
              payload: project,
            });
          }}
          rowSelection={rowSelection}
        />
      ),
    },
  ];

  const onChange = (key: string) => {
    if (key === "1") {
      idClient &&
        getData("project-templates", idClient, undefined, {
          active: true,
        });
    } else if (key === "2") {
      idClient &&
        getData("project-templates", idClient, undefined, {
          active: false,
        });
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title={clientInfo?.name + " - Project Templates"} />
        </ColumnComponent>
        <ColumnComponent>
          <div ref={ref1}>
            <CardComponent
              className="summary-client__card"
              title={""}
              actions={[
                <></>,
                <div style={{ alignSelf: "center" }}>
                  <SearchInput
                    onSearch={(value: string) => {
                      if (value) {
                        idClient &&
                          getData("project-templates", idClient, value, {
                            active: true,
                          });
                      } else {
                        idClient &&
                          getData("project-templates", idClient, undefined, {
                            active: true,
                          });
                      }
                    }}
                  />
                </div>,
                <div ref={ref2}>
                  <Tooltip title="Create new Project" placement="left">
                    <Button
                      type="link"
                      icon={<FolderAddOutlined />}
                      size="large"
                      onClick={() =>
                        setModalStatus({
                          open: true,
                          create: true,
                          update: false,
                          payload: PROJECT_TEMPLATE_INITIAL_STATE,
                        })
                      }
                    />
                  </Tooltip>
                </div>,
                <div ref={ref5}>
                  <Tooltip
                    title={
                      selectedRowsToEdit.length > 0
                        ? "Export Selected Data"
                        : "Export Data"
                    }
                    placement="left"
                  >
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      size="large"
                      onClick={() => {
                        exportProjectTemplateList(
                          projectTemplateList,
                          selectedRowsToEdit as string[]
                        );
                      }}
                    />
                  </Tooltip>
                </div>,
              ]}
            ></CardComponent>
          </div>
        </ColumnComponent>
        <ColumnComponent>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </ColumnComponent>
      </Row>

      <ModalCreateOrUpdateProjectTemplate
        modalStatus={modalStatus}
        onSubmit={(values) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            if (clientInfo && idClient) {
              createOrUpdateProjectTemplate(values, idClient, values._id);
              setModalStatus(MODAL_INITIAL_STATE);
            }
          }
        }}
        onCancel={() => {
          setModalStatus(MODAL_INITIAL_STATE);
        }}
      />

      <DemoModal />
    </>
  );
};
