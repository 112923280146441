import React, { useContext, useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import {
  DashboardOutlined,
  LogoutOutlined,
  UserOutlined,
  TeamOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";

const Navbar = () => {
  const navigate = useNavigate();
  const { onLogout, clientIdSelected, role } = useContext(AuthContext);
  const { projectList, projectSelected, handleChangeProjectSelected } =
    useContext(DataContext);

  const { handleTour } = useContext(DataContext);

  useEffect(() => {
    if (projectList.length > 0) {
      let selected = projectList[0]._id;
      if (!projectSelected) {
        selected && handleChangeProjectSelected(selected);
      }
    }
  }, [projectList]);

  const menuItems: MenuProps["items"] = [
    {
      label: "Dashboard",
      key: `/dashboard/${clientIdSelected}`,
      icon: <DashboardOutlined className="icon" />,
    },
    {
      label: "Customers",
      key: "customer",
      icon: <TeamOutlined className="icon" />,
      children: [
        {
          label: "All Customer",
          key: `/customer/${clientIdSelected}`,
        },
        {
          label: "By Project",
          key: `/customer-project/${clientIdSelected}/${projectSelected}`,
          disabled: !projectSelected,
        },
      ],
    },
    {
      label: "Projects",
      key: "projects",
      icon: <ProjectOutlined className="icon" />,
      children: [
        {
          label: "Projects",
          key: `/project/${clientIdSelected}`,
        },
        {
          label: "Templates",
          key: `/project-template/${clientIdSelected}`,
        },
      ],
    },

    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const adminOptions: MenuProps["items"] = [
    {
      label: "Users",
      key: `/users/${clientIdSelected}`,
      icon: <UserOutlined className="icon" />,
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "logout") {
      onLogout(() => navigate("/login"));
    } else if (key === "help") {
      handleTour(true);
    } else {
      navigate(key);
    }
  };

  return (
    <>
      <Menu
        className="pt-2"
        onClick={onClick}
        defaultSelectedKeys={["1"]}
        mode="vertical"
        items={role === "ADMIN" ? [...adminOptions, ...menuItems] : menuItems}
      />
    </>
  );
};

export default Navbar;
