import { Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useContext, useEffect, useState } from "react";
import { ProjectProps, TagProps } from "../../interfaces/interfaces";
import Link from "antd/es/typography/Link";
import { TablePaginationConfig } from "antd/lib";
import { FilterValue } from "antd/es/table/interface";
import DataContext from "../../context/DataContext";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const ProjectList = ({
  projectList,
  onSelectOne,
  rowSelection,
  hideColumns,
}: {
  projectList: ProjectProps[];
  onSelectOne: (project: ProjectProps) => void;
  rowSelection?: any;
  hideColumns?: boolean;
}) => {
  const [tableParams, setTableParams] = useState<TableParams>({});
  const { dataLoading } = useContext(DataContext);

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: hideColumns ? 5 : 100,
        total: projectList.length,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
  }, [projectList, hideColumns]);

  const columns: ColumnsType<ProjectProps> = [
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      className: "table-row",

      render: (_, { name }) => (
        <div
          style={{
            maxWidth: "100px",
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          <Tooltip title="Click to see Project Info">
            <Link>{name}</Link>
          </Tooltip>
        </div>
      ),
      onCell: (record, index) => {
        return {
          onClick: () => onSelectOne(record),
        };
      },
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 1,
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Template",
      dataIndex: "projectTemplate",
      key: "template",
      className: "table-row",
      render: (_, { projectTemplate }) => (
        <div
          style={{
            maxWidth: "100px",
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {projectTemplate?.name}
        </div>
      ),

      sorter: {
        compare: (a, b) =>
          (a.projectTemplate?.name || "").localeCompare(
            b.projectTemplate?.name || ""
          ),
        multiple: 1,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table-row",
      render: (_, { status }) => <>{status?.name}</>,
      sorter: {
        compare: (a, b) =>
          String(a.status?.name || "").localeCompare(
            String(b.status?.name || "")
          ),
        multiple: 2,
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      className: "table-row",
      render: (_, { startDate }) => (
        <>{startDate ? new Date(startDate).toLocaleDateString() : "N/A"}</>
      ),
      sorter: {
        compare: (a, b) =>
          new Date(a.startDate || 0).getTime() -
          new Date(b.startDate || 0).getTime(),
        multiple: 3,
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      className: "table-row",
      render: (_, { endDate }) => (
        <>{endDate ? new Date(endDate).toLocaleDateString() : "N/A"}</>
      ),
      sorter: {
        compare: (a, b) =>
          new Date(a.endDate || 0).getTime() -
          new Date(b.endDate || 0).getTime(),
        multiple: 4,
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags: TagProps[]) => (
        <div
          style={{ maxWidth: "100px", maxHeight: "100px", overflowY: "hidden" }}
        >
          {tags.map((tag: TagProps) => (
            <Tag key={tag._id} color={tag.color}>
              {tag.name}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      minWidth: 60,
      render: (_, row) => (
        <div
          style={{
            maxWidth: "100px",
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {row.location?.name}
        </div>
      ),
      sorter: (a, b) =>
        (a.assignedTo ? a.assignedTo.name : "").localeCompare(
          b.assignedTo ? b.assignedTo.name : ""
        ),
    },
    {
      title: "Assigned to",
      dataIndex: "assignedTo",
      key: "assignedTo",
      minWidth: 60,
      render: (_, row) => <>{row.assignedTo?.name}</>,
      sorter: (a, b) =>
        (a.assignedTo ? a.assignedTo.name : "").localeCompare(
          b.assignedTo ? b.assignedTo.name : ""
        ),
      hidden: hideColumns,
    },
  ];
  return (
    <Table
      bordered
      loading={dataLoading}
      columns={columns}
      rowSelection={rowSelection}
      dataSource={projectList}
      className="summary-client__table"
      pagination={tableParams.pagination}
      size="small"
      rowKey={"_id"}
      tableLayout="auto"
    />
  );
};
