import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Layout,
  Menu,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import QuickbooksContext from "../../context/QuickbooksContext";
import { NoteProps, TagProps } from "../../interfaces/interfaces";
import { ActivityList } from "../activity";
import { TaskList } from "../task";
import DataContext from "../../context/DataContext";
import { NOTE_INITIAL_STATE } from "../../utils/data";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {
  UserAddOutlined,
  FileAddOutlined,
  FormOutlined,
  ClockCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ZoomInOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";
import { NoteList } from "./note/NoteList";
import { ContactList } from "./contact/ContactList";
import { ModalCreateOrUpdateNote } from "./note/ModalCreateOrUpdateNote";
import AuthContext from "../../context/AuthContext";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import { MenuProps } from "antd/lib";
import { TagSelector } from "../ui";
dayjs.extend(duration);

export const CustomerInfo = ({
  idClient,
  onCreateProject,
}: {
  idClient: string;
  onCreateProject: () => void;
}) => {
  const [siderWidth, setSiderWidth] = useState<number>(450);
  const [collapsed, setCollapsed] = useState(true);
  const { customerInfo, getCustomerData } = useContext(QuickbooksContext);
  const {
    usersList,
    activityList,
    taskList,
    contactList,
    projectSelected,
    openItemModal,
    handleEditData,
    getData,
  } = useContext(DataContext);
  const { profileInfo } = useContext(AuthContext);
  const [projectAssigned, setProjectAssigned] = useState("");
  const [noteModal, setNoteModal] = useState<{
    open: boolean;
    payload: NoteProps | null;
  }>({ open: false, payload: null });
  const [tagsSelected, setTagsSelected] = useState<TagProps[]>([]);

  useEffect(() => {
    getData("activities", idClient, undefined, {
      project: projectSelected,
      customer: customerInfo._id,
    });
    getData("tasks", idClient, undefined, {
      project: projectSelected,
      customer: customerInfo._id,
    });
    getData("contacts", idClient, undefined, {
      customer: customerInfo._id,
    });
    let assigned = customerInfo.Projects?.find(
      (project) => project?.id?._id === projectSelected
    )?.assignedTo?._id;
    assigned && setProjectAssigned(assigned);
    return () => {
      setCollapsed(true);
    };
  }, [projectSelected, customerInfo]);

  const handleFilterByTags = (selectedTags: TagProps[]) => {
    setTagsSelected(selectedTags);
    if (selectedTags.length > 0) {
      if (idClient) {
        getData("activities", idClient, undefined, {
          project: projectSelected,
          customer: customerInfo._id,
          tags: {
            $in: selectedTags.map((tag) => tag._id),
          },
        });
        getData("tasks", idClient, undefined, {
          project: projectSelected,
          customer: customerInfo._id,
          tags: {
            $in: selectedTags.map((tag) => tag._id),
          },
        });
      }
    } else {
      idClient &&
        getData("activities", idClient, undefined, {
          project: projectSelected,
          customer: customerInfo._id,
        });
      idClient &&
        getData("tasks", idClient, undefined, {
          project: projectSelected,
          customer: customerInfo._id,
        });
    }
  };

  const menuItems: MenuProps["items"] = [
    {
      label: "Details",
      key: "details",
      icon: <ZoomInOutlined />,
    },
    {
      label: "Add New Contact",
      key: "new-contact",
      icon: <UserAddOutlined />,
    },
    {
      label: "Add New Note",
      key: "new-note",
      icon: <FileAddOutlined />,
    },
    {
      label: "Add New Activity",
      key: "new-activity",
      icon: <FormOutlined />,
    },
    {
      label: "Add New Project",
      key: "new-project",
      icon: <FolderAddOutlined />,
    },
  ];

  const onClickMenuItem: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "details":
        setCollapsed(!collapsed);
        break;
      case "new-contact":
        openItemModal("contact", true);
        break;
      case "new-note":
        setNoteModal({ open: true, payload: NOTE_INITIAL_STATE });
        break;
      case "new-activity":
        openItemModal("activity", true);
        break;
      case "new-project":
        onCreateProject();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Layout>
        <Sider
          breakpoint="sm"
          trigger={null}
          collapsed={collapsed}
          collapsible
          width={siderWidth}
          style={{ padding: "0 16px" }} // Adjust height based on layout
          theme="light"
          onBreakpoint={(broken) => {
            if (broken) {
              setCollapsed(true);
              setSiderWidth(450);
            }
          }}
        >
          {collapsed ? (
            <Menu onClick={onClickMenuItem} mode="vertical" items={menuItems} />
          ) : (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Assigned to">
                    <Select
                      disabled={!projectSelected}
                      value={projectAssigned}
                      style={{ width: "100%" }}
                      options={usersList.map((user) => ({
                        label: user.name,
                        value: user._id,
                      }))}
                      onChange={(value) => {
                        setProjectAssigned(value);
                        handleEditData({
                          endpoint: `customer/${idClient}/${customerInfo._id}`,
                          newData: {
                            assignedChange: true,
                            assignedTo: value,
                            id: projectSelected,
                          },
                          next: () => {
                            getCustomerData(
                              customerInfo.Id,
                              idClient,
                              () => {}
                            );
                          },
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={12}
                  style={{ textAlign: "center", alignContent: "center" }}
                >
                  <Space>
                    <Tooltip title="Add new contact">
                      <Button
                        onClick={() => openItemModal("contact", true)}
                        icon={<UserAddOutlined />}
                        type="default"
                      ></Button>
                    </Tooltip>
                    <Tooltip title="Add new Note">
                      <Button
                        icon={<FileAddOutlined />}
                        onClick={() =>
                          setNoteModal({
                            open: true,
                            payload: NOTE_INITIAL_STATE,
                          })
                        }
                      ></Button>
                    </Tooltip>
                    <Tooltip title="Add new Activity">
                      <Button
                        icon={<FormOutlined />}
                        disabled={!projectSelected}
                        onClick={() => openItemModal("activity", true)}
                      ></Button>
                    </Tooltip>
                    <Tooltip title="Add new Task">
                      <Button
                        icon={<ClockCircleOutlined />}
                        disabled={!projectSelected}
                        onClick={() => openItemModal("task", true)}
                      ></Button>
                    </Tooltip>
                  </Space>
                </Col>
              </Row>

              <Divider />
              <NoteList notes={customerInfo.CustomerNotes} />
              <Divider />
              <ContactList
                contacts={contactList}
                filterContacts={(search) => {
                  getData("contacts", idClient, search, {
                    customer: customerInfo._id,
                  });
                }}
                idClient={idClient}
                next={() =>
                  getData("contacts", idClient, undefined, {
                    customer: customerInfo._id,
                  })
                }
              />
            </>
          )}
        </Sider>
        <Layout>
          <Header
            className="protected-layout__header"
            style={{ padding: 0, maxHeight: "30px" }}
          >
            <Tooltip title="Details">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
              />
            </Tooltip>
            <div style={{ marginRight: "20px" }}>
              <TagSelector
                className="summary-client__card__actions__select"
                size="large"
                selectedTags={tagsSelected}
                setSelectedTags={(selected) => handleFilterByTags(selected)}
                filtering={true}
              />
            </div>
          </Header>

          <Content className="protected-layout__dashboard">
            <>
              <ActivityList
                activityList={activityList}
                idProject={projectSelected}
                onAddNewActivity={() => openItemModal("activity", true)}
                onEditActivity={(activity) =>
                  openItemModal("activity", false, activity)
                }
                onDuplicateActivity={(activity) =>
                  openItemModal("activity", true, { ...activity, _id: "" })
                }
              />
              <TaskList
                taskList={taskList}
                idProject={projectSelected}
                onAddNewTask={() => openItemModal("task", true)}
                onEditTask={(task) => openItemModal("task", false, task)}
                onDuplicateTask={(task) =>
                  openItemModal("task", true, { ...task, _id: "" })
                }
              />
            </>
          </Content>
        </Layout>
      </Layout>

      <ModalCreateOrUpdateNote
        onCancel={() => setNoteModal({ open: false, payload: null })}
        modalStatus={noteModal}
        onSubmit={(values) => {
          if (profileInfo) {
            let newNote = {
              ...values,
              noteBy: profileInfo._id,
            };
            handleEditData({
              endpoint: `customer/${idClient}/${customerInfo._id}`,
              newData: { newNote },
              next: () => {
                getCustomerData(customerInfo.Id, idClient, () => {});
                setNoteModal({ open: false, payload: null });
              },
            });
          } else {
            setNoteModal({ open: false, payload: null });
          }
        }}
      />
    </>
  );
};
