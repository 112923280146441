import React from "react";
import { ALERT_INITIAL_STATE } from "../utils/data";
import {
  ClientProps,
  UserProps,
  UserAccountProps,
  LocationProps,
} from "../interfaces/interfaces";

interface Props {
  isLoggedIn: boolean;
  role: null | "CLIENT" | "ADMIN" | "USER" | "DEMO";
  authSuccess: { status: boolean; message: string };
  authError: { status: boolean; message: string };
  authLoading: boolean;
  clientInfo: null | ClientProps;
  userInfo: null | {
    name: string;
    user: string;
    _id: string;
  };
  dataWarning: {
    status: boolean;
    message: string;
    actionLabel?: string;
    action?: () => void;
  };
  isDemoEnv: boolean;
  clientList: UserAccountProps[] | null;
  clientIdSelected: string;
  profileInfo: UserProps | null;
  isModalResetPasswordVisible: boolean;
  clientInformation: ClientProps | null;
  openCloseModalResetPassword: (status: boolean) => void;
  handleSendResetPassword: ({
    username,
    next,
  }: {
    username: string;
    next: () => void;
  }) => void;
  changeProfileInfo: (value: UserProps) => void;
  handleChangeClientSelected: (id: string, redirect?: boolean) => void;
  handleLoading: (status: boolean) => void;
  handleWarning: (
    status: boolean,
    message: string,
    actionLabel?: string,
    action?: () => void
  ) => void;
  handleSuccess: (status: boolean, message: string) => void;
  onLogin: (username: string, password: string, quickbooks: boolean) => void;
  onLogout: (next?: () => void) => void;
  removeAuthData: () => void;
  validateToken: () => boolean;
  getClientId: () => string | null;
  handleLoginQuickbooks: (idClient?: string) => void;
  validateExternalSession: ({
    token,
    realmId,
    clientId,
    launch,
    redirectTo,
    revoke,
  }: {
    token: string;
    realmId?: string;
    clientId?: string;
    launch?: boolean;
    redirectTo?: string;
    revoke?: boolean;
  }) => void;
  handleLoginQuickbooksSSO: () => void;
  handleLoginDemo: (email: string) => void;
  handleRefreshTokenCollections: (next?: () => void) => void;
  compareToken: (token: string) => boolean;
  getClientInformation: (idClient: string, next: () => void) => void;
}

const AuthContext = React.createContext<Props>({
  isLoggedIn: false,
  role: null,
  authSuccess: ALERT_INITIAL_STATE,
  authError: ALERT_INITIAL_STATE,
  authLoading: false,
  clientInfo: null,
  userInfo: null,
  dataWarning: { status: false, message: "" },
  isDemoEnv: false,
  clientList: [],
  clientIdSelected: "",
  profileInfo: null,
  isModalResetPasswordVisible: false,
  clientInformation: null,
  openCloseModalResetPassword: () => {},
  handleSendResetPassword: () => {},
  changeProfileInfo: () => {},
  handleChangeClientSelected: () => {},
  handleSuccess: () => {},
  handleWarning: () => {},
  handleLoading: () => {},
  onLogin: () => {},
  onLogout: () => {},
  removeAuthData: () => {},
  validateToken: () => false,
  getClientId: () => null,
  handleLoginQuickbooks: () => {},
  validateExternalSession: () => {},
  handleLoginQuickbooksSSO: () => {},
  handleLoginDemo: () => {},
  handleRefreshTokenCollections: () => {},
  compareToken: () => false,
  getClientInformation: () => {},
});

export default AuthContext;
