import React from "react";
import { List } from "antd";
import { NoteProps } from "../../../interfaces/interfaces";
import dayjs from "dayjs";

export const NoteList = ({ notes }: { notes: NoteProps[] }) => {
  // Table columns for notes
  return (
    <List
      itemLayout="horizontal"
      pagination={{ pageSize: 3, position: "top", align: "end" }}
      dataSource={notes}
      locale={{ emptyText: "No notes" }}
      renderItem={(note: NoteProps) => (
        <List.Item>
          <List.Item.Meta title={note?.noteBy?.name} description={note.body} />
          <div>{dayjs(note.date).format("MM/DD/YY HH:mm")}</div>
        </List.Item>
      )}
    />
  );
};
