import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { CustomerInfo } from "../customer";
import QuickbooksContext from "../../context/QuickbooksContext";
import { useDemoModal } from "../../hooks";
import { AlertComponent } from "../ui/AlertComponent";
import DataContext from "../../context/DataContext";
import { USDollar } from "../../utils/func/currency";
import {
  CustomerProps,
  ProjectProps,
  TagProps,
} from "../../interfaces/interfaces";
import { PlusOutlined } from "@ant-design/icons";
import { DrawerCreateOrUpdateProject } from "../project";
import { MODAL_INITIAL_STATE, PROJECT_INITIAL_STATE } from "../../utils/data";
import AuthContext from "../../context/AuthContext";
const { Text } = Typography;

interface Props {
  open: boolean;
  idClient: string;
  idCustomer: string | null;
  onSelectOne: (record: CustomerProps) => void;
  onClose: () => void;
  projectPreselected?: string | null;
}

export const CustomerDrawer = ({
  open,
  idClient,
  idCustomer,
  onSelectOne,
  onClose,
  projectPreselected,
}: Props) => {
  const { DemoModal } = useDemoModal();
  const { getCustomerData, alertData, qbLoading, customerInfo } =
    useContext(QuickbooksContext);
  const {
    projectSelected,
    getData,
    handleChangeProjectSelected,
    handleEditData,
    handleCreateData,
  } = useContext(DataContext);
  const { getClientInformation } = useContext(AuthContext);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [projectDrawerStatus, setProjectDrawerStatus] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    payload: ProjectProps | null;
  }>({
    open: false,
    create: false,
    update: false,
    payload: null,
  });

  useEffect(() => {
    idClient &&
      idCustomer &&
      getCustomerData(idCustomer, idClient, () => setOpenDrawer(true));
  }, [idCustomer]);

  useEffect(() => {
    if (projectPreselected) {
      handleChangeProjectSelected(projectPreselected);
    } else {
      let selected = customerInfo?.Projects?.[0]?.id?._id;
      selected && handleChangeProjectSelected(selected);
    }
  }, [projectPreselected, customerInfo]);

  useEffect(() => {
    customerInfo.Projects?.length === 0 && handleChangeProjectSelected(null);
  }, [customerInfo]);

  const updateProject = (
    values: ProjectProps,
    idClient: string,
    idProject?: string
  ) => {
    if (projectDrawerStatus.create) {
      handleCreateData({
        endpoint: "projects",
        idClient,
        data: values,
        next: () => {
          idClient &&
            idCustomer &&
            getCustomerData(idCustomer, idClient, () => {});
          setProjectDrawerStatus(MODAL_INITIAL_STATE);
        },
      });
    }
  };

  return (
    <>
      <Drawer
        title={
          <>
            {customerInfo.DisplayName}
            <Row gutter={16}>
              <Col span={12}>
                <Text>
                  Balance: {USDollar.format(customerInfo?.Balance) || 0}
                </Text>
                <br />
                {customerInfo?.PrimaryEmailAddr?.Address && (
                  <>
                    <Text>{customerInfo?.PrimaryEmailAddr?.Address || ""}</Text>
                    {" / "}
                    <Text>
                      {customerInfo?.PrimaryPhone.FreeFormNumber || ""}
                    </Text>
                  </>
                )}
              </Col>
            </Row>
          </>
        }
        width={"100vw"}
        placement="top"
        height={"100vh"}
        styles={{
          body: {
            padding: 0,
          },
        }}
        onClose={() => {
          onClose();
          setOpenDrawer(false);
        }}
        maskClosable={false}
        open={openDrawer}
        loading={qbLoading}
        extra={[
          <Space>
            {customerInfo.parent?.Id ? (
              <>
                Related Customers:
                <Button
                  type="link"
                  onClick={() =>
                    customerInfo.parent && onSelectOne(customerInfo.parent)
                  }
                >
                  {customerInfo.parent.DisplayName}
                </Button>
              </>
            ) : null}
            {customerInfo?.children && customerInfo?.children?.length > 0 ? (
              <>
                Related Customers:
                <Select
                  style={{ width: 200 }}
                  placeholder="Relationships"
                  options={customerInfo.children?.map((child) => ({
                    label: child.DisplayName,
                    value: child.Id,
                  }))}
                  onChange={(value) => {
                    onSelectOne(
                      customerInfo.children?.find(
                        (child) => child.Id === value
                      ) as CustomerProps
                    );
                  }}
                ></Select>
              </>
            ) : null}

            <Select
              style={{ width: 200 }}
              disabled={!!projectPreselected}
              placeholder="Project"
              options={customerInfo.Projects?.map((project) => ({
                label: project?.id?.name,
                value: project?.id?._id,
              }))}
              value={projectSelected}
              onChange={(value) => {
                handleChangeProjectSelected(value);
                getData("activities", idClient, undefined, {
                  project: value,
                  customer: customerInfo._id,
                });
                getData("tasks", idClient, undefined, {
                  project: value,
                  customer: customerInfo._id,
                });
              }}
            ></Select>
            <Tooltip title="New Project">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() =>
                  setProjectDrawerStatus({
                    open: true,
                    create: true,
                    update: false,
                    payload: {
                      ...PROJECT_INITIAL_STATE,
                      customers: [customerInfo._id],
                    },
                  })
                }
              >
                New
              </Button>
            </Tooltip>
          </Space>,
        ]}
      >
        {alertData.message && <AlertComponent {...alertData} />}
        <CustomerInfo
          idClient={idClient}
          onCreateProject={() =>
            setProjectDrawerStatus({
              open: true,
              create: true,
              update: false,
              payload: {
                ...PROJECT_INITIAL_STATE,
                customers: [customerInfo._id],
              },
            })
          }
        />
      </Drawer>
      <DemoModal />
      <DrawerCreateOrUpdateProject
        modalStatus={projectDrawerStatus}
        onSubmit={(values) => {
          if (idClient) {
            updateProject(values, idClient, values._id);
          }
        }}
        handleCreateLocation={(locationName) => {
          handleEditData({
            endpoint: `client/${idClient}`,
            newData: {
              newLocation: locationName,
            },
            next: () => {
              idClient && getClientInformation(idClient, () => {});
            },
          });
        }}
        handleCreateNewProjectStatus={(projectStatusName) => {
          handleEditData({
            endpoint: `client/${idClient}`,
            newData: {
              newProjectStatus: projectStatusName,
            },
            next: () => {
              idClient && getClientInformation(idClient, () => {});
            },
          });
        }}
        onCancel={() => {
          setProjectDrawerStatus(MODAL_INITIAL_STATE);
        }}
      />
    </>
  );
};
