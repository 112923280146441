import { Form, Input, DatePicker, Button, Modal, Row, Col } from "antd";
import dayjs from "dayjs";
import { NoteProps } from "../../../interfaces/interfaces";
import { useEffect, useState } from "react";
import { NOTE_INITIAL_STATE } from "../../../utils/data";

interface Props {
  modalStatus: {
    open: boolean;
    payload?: NoteProps | null;
  };
  onCancel: () => void;
  onSubmit: (values: NoteProps) => void;
}

export const ModalCreateOrUpdateNote = ({
  modalStatus: { open, payload },
  onCancel,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState<NoteProps>(NOTE_INITIAL_STATE);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(NOTE_INITIAL_STATE);
      setValues(NOTE_INITIAL_STATE);
    }
  }, [values]);

  useEffect(() => {
    if (payload) {
      setValues({ ...payload, date: dayjs() });
    } else {
      setValues(NOTE_INITIAL_STATE);
    }
  }, [payload]);

  return (
    <Modal
      title="New Note"
      open={open}
      centered
      onOk={() => {
        onCancel();
        form.resetFields();
        setValues(NOTE_INITIAL_STATE);
      }}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setValues(NOTE_INITIAL_STATE);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={() => {
          onSubmit({ ...values });
        }}
        form={form}
        // initialValues={values}
      >
        <Row gutter={24}>
          {/* Left Column */}
          <Col span={24}>
            <Form.Item label="Date">
              <DatePicker
                allowClear={false}
                showTime={{ format: "HH:mm" }}
                format="MM/DD/YYYY HH:mm"
                value={values.date ? dayjs(values.date) : undefined}
                onChange={(value) => {
                  if (value) {
                    setValues({
                      ...values,
                      date: value,
                    });
                  }
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label="New Note"
              name="body"
              rules={[{ required: true, message: "Note field is required" }]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Write a note"
                onChange={(event) =>
                  setValues({ ...values, body: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Buttons */}
        <Row justify="end">
          <Col>
            <Button
              type="default"
              onClick={onCancel}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save Note
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
