import { Button, Card, Space, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useContext } from "react";
import {
  CustomerProps,
  TagProps,
  TaskProps,
} from "../../interfaces/interfaces";
import {
  EditOutlined,
  CopyOutlined,
  MailOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  IssuesCloseOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";
import DataContext from "../../context/DataContext";
import { TagSelector } from "../ui";

export const TaskList = ({
  taskList,
  onAddNewTask,
  onEditTask,
  onDuplicateTask,
  hideColumns,
  idProject,
}: {
  taskList: TaskProps[];
  onAddNewTask: () => void;
  onEditTask: (record: TaskProps) => void;
  onDuplicateTask: (record: TaskProps) => void;
  hideColumns?: boolean;
  idProject?: string | null;
}) => {
  const { dataLoading } = useContext(DataContext);
  const taskColumns: ColumnsType<TaskProps> = [
    {
      title: "Actions",
      key: "edit",
      render: (params) => (
        <Space>
          <Tooltip title="Edit" placement="top">
            <Button
              onClick={() => onEditTask(params)}
              icon={<EditOutlined />}
              size="small"
            ></Button>
          </Tooltip>
          <Tooltip title="Duplicate" placement="top">
            <Button
              onClick={() => onDuplicateTask(params)}
              icon={<CopyOutlined />}
              size="small"
            ></Button>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "typeOfTask",
      key: "typeOfTask",
      render: (typeOfTask: TaskProps["typeOfTask"]) =>
        typeOfTask === "Call" ? (
          <>
            <Tag color="lime" icon={<PhoneOutlined />}>
              Call
            </Tag>
          </>
        ) : typeOfTask === "Email" ? (
          <>
            <Tag color="cyan" icon={<MailOutlined />}>
              Email
            </Tag>
          </>
        ) : typeOfTask === "Follow-up" ? (
          <>
            <Tag color="magenta" icon={<CheckCircleOutlined />}>
              Follow-up
            </Tag>
          </>
        ) : typeOfTask === "Meeting" ? (
          <>
            <Tag color="geekblue" icon={<TeamOutlined />}>
              Meeting
            </Tag>
          </>
        ) : (
          <Tag color="orange" icon={<IssuesCloseOutlined />}>
            {typeOfTask}
          </Tag>
        ),
      sorter: (a, b) => a.typeOfTask.localeCompare(b.typeOfTask),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "subject",
      render: (customer: CustomerProps) => <span>{customer.DisplayName}</span>,
      hidden: !hideColumns,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description) => (
        <div
          style={{ maxWidth: "100px", maxHeight: "100px", overflowY: "hidden" }}
        >
          {description}
        </div>
      ),
      hidden: hideColumns,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority: string) => (
        <Tag
          color={
            priority === "High"
              ? "red"
              : priority === "Medium"
                ? "orange"
                : "green"
          }
        >
          {priority}
        </Tag>
      ),
      sorter: (a, b) => a.priority.localeCompare(b.priority),
      responsive: ["xl"],
      hidden: hideColumns,
    },
    {
      title: "Date",
      dataIndex: "startDate",
      className: "align-center",
      key: "startDate",
      render: (startDate: string) =>
        startDate ? dayjs(startDate).format("MM/DD/YYYY") : "-",
      sorter: (a, b) =>
        (a.startDate
          ? dayjs(a.startDate).format("YYYY-MM-DD")
          : ""
        ).localeCompare(
          b.startDate ? dayjs(b.startDate).format("YYYY-MM-DD") : ""
        ),
      responsive: ["xl"],
    },
    {
      title: "Next",
      dataIndex: "nextDate",
      className: "align-center",
      key: "nextDate",
      render: (dueDate: string) =>
        dueDate ? dayjs(dueDate).format("MM/DD/YYYY") : "-",
      sorter: (a, b) =>
        (a.nextDate
          ? dayjs(a.nextDate).format("YYYY-MM-DD")
          : ""
        ).localeCompare(
          b.nextDate ? dayjs(b.nextDate).format("YYYY-MM-DD") : ""
        ),
      responsive: ["xl"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <Tag
          color={
            status === "Pending"
              ? "red"
              : status === "In Progress"
                ? "gold"
                : status === "Cancelled"
                  ? "blue"
                  : "green"
          }
        >
          {status}
        </Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags: TagProps[]) => (
        <div
          style={{ maxWidth: "100px", maxHeight: "100px", overflowY: "hidden" }}
        >
          {tags.map((tag: TagProps) => (
            <Tag key={tag._id} color={tag.color}>
              {tag.name}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Recurring",
      dataIndex: "isRecurring",
      key: "isRecurring",
      render: (isRecurring: boolean, record: TaskProps) =>
        isRecurring ? <Tag color="purple">{record.frequency}</Tag> : "-",
      sorter: (a, b) =>
        (a.frequency ? a.frequency : "").localeCompare(
          b.frequency ? b.frequency : ""
        ),
      responsive: ["xl"],
      hidden: hideColumns,
    },
    {
      title: "Assigned",
      dataIndex: "assignedTo",
      key: "assignedTo",
      render: (_, row) => <>{row.assignedTo?.name}</>,
      sorter: (a, b) =>
        (a.assignedTo?.name ? a.assignedTo?.name : "").localeCompare(
          b.assignedTo?.name ? b.assignedTo?.name : ""
        ),
    },
  ];
  return (
    <Card
      title="Tasks"
      style={{ marginBottom: 16 }}
      extra={
        hideColumns
          ? null
          : [
              <Tooltip title="Add new Task" placement="top">
                <Button
                  type="primary"
                  disabled={!idProject}
                  onClick={onAddNewTask}
                  icon={<ClockCircleOutlined />}
                ></Button>
              </Tooltip>,
            ]
      }
    >
      <Table
        loading={dataLoading}
        columns={taskColumns}
        dataSource={taskList}
        rowKey="_id"
        pagination={{ pageSize: 5 }}
        size="small"
        locale={{ emptyText: "No tasks " }}
      />
    </Card>
  );
};
