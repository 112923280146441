import * as XLSX from "xlsx";
import {
  CustomerProps,
  DetailProps,
  InvoiceProps,
  ProjectProps,
  ProjectTemplateProps,
  SummaryDataProps,
  TemplateProps,
  UserActionProps,
  UserProps,
} from "../../interfaces/interfaces";
import dayjs from "dayjs";
import { USDollar } from "./currency";
import { Key } from "react";

interface ExportableDataProps {
  dataSheet: Object[];
  name: string;
}

const sanitizeCellValue = (value: string) => {
  if (typeof value === "string" && /^[=+\-@]/.test(value)) {
    return `'${value}`;
  }
  return value;
};

const getTotalValue = (invoices: InvoiceProps[]) => {
  return USDollar.format(
    invoices.reduce((acc, invoice) => {
      return acc + invoice.Balance;
    }, 0)
  );
};

const getInvoiceDetails = (invoices: InvoiceProps[]) => {
  let exportableInvoiceDetails: any[] = [];
  invoices.forEach((invoice) => {
    exportableInvoiceDetails.push({
      Date:
        invoice.MetaData &&
        dayjs(invoice.MetaData.CreateTime).format("MM/DD/YYYY"),
      Name: invoice.CustomerRef && sanitizeCellValue(invoice.CustomerRef?.name),
      Num: invoice.DocNumber,
      "Transaction Type":
        invoice.TransactionType && sanitizeCellValue(invoice.TransactionType),
      Terms:
        invoice.SalesTermRef && sanitizeCellValue(invoice.SalesTermRef?.name),
      "Due Date": invoice.DueDate,
      // ? dayjs(invoice.DueDate).utc().format("MM/DD/YYYY")
      // : invoice.TxnDate
      //   ? dayjs(invoice.TxnDate).utc().format("MM/DD/YYYY")
      //   : "",
      "Open Balance": invoice.Balance && USDollar.format(invoice.Balance),
      Status: invoice.status_field ? invoice.status_field[0].Label : "",
      "Last Change":
        invoice.CollectionDetails.LastContact &&
        dayjs(invoice.CollectionDetails.LastContact).format("MM/DD/YYYY"),
      "Last Note":
        invoice.CollectionDetails?.LastNotes &&
        invoice.CollectionDetails?.LastNotes.length > 0
          ? invoice.CollectionDetails.LastNotes[0].body
          : "",
      By:
        invoice.CollectionDetails.LastNotes &&
        invoice.CollectionDetails?.LastNotes.length > 0
          ? invoice.CollectionDetails.LastNotes[0].user.name
          : "",
      Email:
        invoice.CollectionDetails?.EmailAddress &&
        sanitizeCellValue(invoice.CollectionDetails?.EmailAddress),
      "Phone Number": invoice.CollectionDetails?.PhoneNumber,
    });
  });
  return exportableInvoiceDetails;
};

export const exportSummaryFile = (
  summary: SummaryDataProps,
  selectedInvoices?: string[]
) => {
  if (selectedInvoices && selectedInvoices.length > 0) {
    const selectedData = summary.data.filter((line) =>
      selectedInvoices.includes(line._id.value)
    );
    const data = selectedData.map((line) => {
      return {
        name: sanitizeCellValue(line._id.name),
        current: line.current && getTotalValue(line.current),
        "1-30": line["1-30"] && getTotalValue(line["1-30"]),
        "31-60": line["31-60"] && getTotalValue(line["31-60"]),
        "61-90": line["61-90"] && getTotalValue(line["61-90"]),
        ">90": line[">90"] && getTotalValue(line[">90"]),
        total: line.total && USDollar.format(line.total),
      };
    });
    handleExportExcel([{ dataSheet: data, name: `Summary` }], "Summary Report");
  } else {
    const data = summary.data.map((line) => {
      return {
        name: line._id.name,
        current: line.current && getTotalValue(line.current),
        "1-30": line["1-30"] && getTotalValue(line["1-30"]),
        "31-60": line["31-60"] && getTotalValue(line["31-60"]),
        "61-90": line["61-90"] && getTotalValue(line["61-90"]),
        ">90": line[">90"] && getTotalValue(line[">90"]),
        total: USDollar.format(line.total),
      };
    });
    handleExportExcel([{ dataSheet: data, name: `Summary` }], "Summary Report");
  }
};
export const exportCustomerFile = (
  customerList: CustomerProps[],
  selectedCustomers?: string[]
) => {
  let selectedData = customerList;
  if (selectedCustomers && selectedCustomers.length > 0) {
    selectedData = customerList.filter((customer) =>
      selectedCustomers.includes(customer.Id)
    );
  }
  const data = selectedData.map((customer) => {
    return {
      name: customer.DisplayName && sanitizeCellValue(customer.DisplayName),
      Email:
        customer.PrimaryEmailAddr &&
        sanitizeCellValue(customer.PrimaryEmailAddr?.Address),
      Phone: customer.PrimaryPhone ? customer.PrimaryPhone?.FreeFormNumber : "",
      Address: customer.BillAddr && sanitizeCellValue(customer.BillAddr?.Line1),
      "Assigned To": customer.assignedTo ? customer.assignedTo.name : "",
      Balance: customer.Balance
        ? USDollar.format(customer.Balance)
        : USDollar.format(0),
    };
  });
  handleExportExcel(
    [{ dataSheet: data, name: `Customer List` }],
    "Customer List"
  );
};
export const exportProjectList = (
  projectList: ProjectProps[],
  selectedProjects?: string[]
) => {
  let selectedData = projectList;
  if (selectedProjects && selectedProjects.length > 0) {
    selectedData = projectList.filter((project) =>
      selectedProjects.includes(project._id)
    );
  }
  const data = selectedData.map((project) => {
    return {
      Name: project.name,
      Status: project.status,
      "Start Date":
        project.startDate && dayjs(project.startDate).format("MM/DD/YYYY"),
      "End Date":
        project.endDate && dayjs(project.endDate).format("MM/DD/YYYY"),
      "Assigned to": project.assignedTo ? project.assignedTo?.name : "",
    };
  });
  handleExportExcel(
    [{ dataSheet: data, name: `Project List` }],
    "Project List"
  );
};
export const exportProjectTemplateList = (
  projectList: ProjectTemplateProps[],
  selectedProjects?: string[]
) => {
  let selectedData = projectList;
  if (selectedProjects && selectedProjects.length > 0) {
    selectedData = projectList.filter((project) =>
      selectedProjects.includes(project._id)
    );
  }
  const data = selectedData.map((project) => {
    return {
      Name: project.name,
      Status: project.status,
      "Assigned to": project.assignedTo ? project.assignedTo?.name : "",
    };
  });
  handleExportExcel(
    [{ dataSheet: data, name: `Project Templates` }],
    "Project Templates"
  );
};

export const exportUserList = (
  userList: UserProps[],
  selectedUsers?: string[]
) => {
  let selectedData = userList;
  if (selectedUsers && selectedUsers.length > 0) {
    selectedData = userList.filter((project) =>
      selectedUsers.includes(project._id)
    );
  }
  const data = selectedData.map((user) => {
    return {
      Name: user.name,
      Email: user.username || user.email,
      Role: user.role ? user.role?.description : "",
      "Last login":
        user.lastLogin && dayjs(user.lastLogin).format("MM/DD/YYYY"),
    };
  });
  handleExportExcel([{ dataSheet: data, name: `User List` }], "User List");
};

export const exportTemplateFile = (
  templateList: TemplateProps[],
  selectedTemplates?: string[]
) => {
  let selectedData = templateList;
  if (selectedTemplates && selectedTemplates.length > 0) {
    selectedData = templateList.filter((template) =>
      selectedTemplates.includes(template._id)
    );
  }
  const data = selectedData.map((template) => {
    return {
      "Template Name": sanitizeCellValue(template.TemplateName),
      "Template Subject": sanitizeCellValue(template.Subject),
      Content: sanitizeCellValue(template.TemplateContent),
      Active: template.Active ? "Yes" : "No",
      Default: template.Default ? "Yes" : "No",
      "Individual/List": template.Multiple ? "List" : "Individual",
    };
  });
  handleExportExcel(
    [{ dataSheet: data, name: `Template List` }],
    "Template List"
  );
};

export const exportDetailFile = (
  detail: DetailProps,
  {
    selectedCurrent,
    selected_31_60,
    selected_61_90,
    selected_90,
  }: {
    selectedCurrent: Key[];
    selected_1_30: Key[];
    selected_31_60: Key[];
    selected_61_90: Key[];
    selected_90: Key[];
  }
) => {
  const anySelected =
    selectedCurrent.length > 0 ||
    selected_31_60.length > 0 ||
    selected_61_90.length > 0 ||
    selected_90.length > 0;
  const dataCurrent = anySelected
    ? detail.current.filter((invoice) =>
        selectedCurrent.includes(invoice.DocNumber)
      )
    : detail.current;
  const data1_30 = anySelected
    ? detail["1-30"].filter((invoice) =>
        selected_31_60.includes(invoice.DocNumber)
      )
    : detail["1-30"];
  const data31_60 = anySelected
    ? detail["31-60"].filter((invoice) =>
        selected_31_60.includes(invoice.DocNumber)
      )
    : detail["31-60"];
  const data61_90 = anySelected
    ? detail["61-90"].filter((invoice) =>
        selected_61_90.includes(invoice.DocNumber)
      )
    : detail["61-90"];
  const data90 = anySelected
    ? detail[">90"].filter((invoice) => selected_90.includes(invoice.DocNumber))
    : detail[">90"];

  try {
    let exportableData: ExportableDataProps[] = [];
    detail.current.length > 0 &&
      exportableData.push({
        name: "Current",
        dataSheet: getInvoiceDetails(dataCurrent),
      });
    detail["1-30"].length > 0 &&
      exportableData.push({
        name: "1-30",
        dataSheet: getInvoiceDetails(data1_30),
      });
    detail["31-60"].length > 0 &&
      exportableData.push({
        name: "31-60",
        dataSheet: getInvoiceDetails(data31_60),
      });
    detail["61-90"].length > 0 &&
      exportableData.push({
        name: "61-90",
        dataSheet: getInvoiceDetails(data61_90),
      });
    detail[">90"].length > 0 &&
      exportableData.push({
        name: ">90",
        dataSheet: getInvoiceDetails(data90),
      });
    if (exportableData.length > 0) {
      let total: any = [];
      exportableData.forEach((data) => {
        total = [...total, ...data.dataSheet];
      });
      exportableData.push({
        name: "Total",
        dataSheet: total,
      });
    }
    handleExportExcel(exportableData, "Detail Report");
  } catch (e) {
    console.log(e);
  }
};

export const exportTransactionList = (
  transactionList: InvoiceProps[],
  name: "Invoice" | "Payment" | "Journal Entry" | "Credit" | "Deposit",
  selectedTransactions?: string[]
) => {
  try {
    let exportableData: InvoiceProps[] = transactionList;
    if (selectedTransactions && selectedTransactions.length > 0) {
      exportableData = transactionList.filter((transaction) =>
        selectedTransactions.includes(transaction.Id)
      );
    }
    exportableData.length > 0 &&
      handleExportExcel(
        [{ dataSheet: getInvoiceDetails(exportableData), name: "" }],
        name
      );
  } catch (e) {
    console.log(e);
  }
};

export const exportReport = (invoices: InvoiceProps[]) => {
  const data = invoices.map((invoice) => {
    return {
      Date:
        invoice.MetaData &&
        dayjs(invoice.MetaData.CreateTime).format("MM/DD/YYYY"),
      Name: invoice.CustomerRef && sanitizeCellValue(invoice.CustomerRef?.name),
      Num: invoice.DocNumber,
      Terms: invoice.SalesTermRef && invoice.SalesTermRef?.name,
      "Due Date": invoice.DueDate,
      // ? dayjs(invoice.DueDate).utc().format("MM/DD/YYYY")
      // : invoice.TxnDate
      //   ? dayjs(invoice.TxnDate).utc().format("MM/DD/YYYY")
      //   : "",
      "Open Balance":
        invoice.DocNumber &&
        invoice.Balance &&
        USDollar.format(invoice.Balance),
      "Last Contact":
        invoice.CollectionDetails?.LastContact &&
        dayjs(invoice.CollectionDetails?.LastContact).format("MM/DD/YYYY"),
      Status: invoice.CollectionDetails?.Status.Label,
      "Last Note":
        invoice.CollectionDetails?.LastNotes &&
        invoice.CollectionDetails?.LastNotes?.length > 0
          ? sanitizeCellValue(
              invoice?.CollectionDetails?.LastNotes[0]?.body as string
            )
          : "",
      By:
        invoice?.CollectionDetails?.LastNotes &&
        invoice?.CollectionDetails?.LastNotes?.length > 0
          ? sanitizeCellValue(
              invoice?.CollectionDetails?.LastNotes[0]?.user?.name
            )
          : "",
    };
  });
  handleExportExcel(
    [{ dataSheet: data, name: "Custom Report" }],
    "Custom Report"
  );
};

export const exportLogs = (actions: UserActionProps[]) => {
  const data = actions.map((action) => {
    return {
      Date:
        action.timestamp &&
        dayjs(action.timestamp).format("MM-DD-YYYY HH:mm:ss"),
      User: action.user?.email || "",
      Action: action.action || "",
    };
  });
  handleExportExcel([{ dataSheet: data, name: "Audit Logs" }], "Audit Logs");
};

const handleExportExcel = (
  exportData: ExportableDataProps[],
  name?: string
) => {
  let fileName = name || "report";
  try {
    const workbook = XLSX.utils.book_new();
    exportData.forEach((data) => {
      const worksheet1 = XLSX.utils.json_to_sheet(data.dataSheet);
      XLSX.utils.book_append_sheet(workbook, worksheet1, data.name);
    });
    XLSX.writeFile(
      workbook,
      `${fileName} - ${dayjs().format("MM.DD.YYYY")}.xlsx`
    );
  } catch (e) {}
};
