import React, { useContext, useEffect, useState } from "react";
import { useRequest } from "../hooks";
import DataContext from "./DataContext";
import {
  ActivityProps,
  ClientProps,
  ContactProps,
  CustomerProps,
  FilterProps,
  InvoiceProps,
  ListComponentProps,
  ProjectProps,
  ProjectTemplateProps,
  QuickbooksCompanyProps,
  StatusProps,
  TagProps,
  TaskProps,
  TemplateProps,
  ToDoProps,
  UserAccountProps,
  UserProps,
} from "../interfaces/interfaces";
import {
  ALERT_INITIAL_STATE,
  ERROR_ENCRYPTED,
  STATUS_INITIAL_LIST,
} from "../utils/data";
import AuthContext from "./AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotification } from "./NotificationProvider";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const LIST_INITIAL_STATE = [{ label: "", value: "" }];

const DataProvider = ({ children }: Props) => {
  const { pathname } = useLocation();
  const { handleRequest } = useRequest();
  const { onLogout, profileInfo, changeProfileInfo, clientIdSelected } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({ status: false, message: "" });
  const [isSuccess, setIsSuccess] = useState({ status: false, message: "" });
  const [statusList, setStatusList] =
    useState<StatusProps[]>(STATUS_INITIAL_LIST);
  const [isModalStatusOpen, SetIsModalStatusOpen] = useState(false);
  const [statusToEdit, setStatusToEdit] = useState<StatusProps | null>(null);
  const [invoiceList, setInvoiceList] = useState<InvoiceProps[]>([]);
  const [customerList, setCustomerList] = useState<CustomerProps[]>([]);
  const [customerByClient, setCustomerByClient] = useState<CustomerProps[]>([]);
  const [customerByProject, setCustomerByProject] = useState<CustomerProps[]>(
    []
  );
  const [isWarning, setIsWarning] = useState<{
    status: boolean;
    message: string;
    actionLabel?: string;
    action?: () => void;
  }>({ status: false, message: "" });
  const [customerOptions, setCustomerOptions] =
    useState<ListComponentProps[]>(LIST_INITIAL_STATE);
  const [statusOptions, setStatusOptions] =
    useState<ListComponentProps[]>(LIST_INITIAL_STATE);
  const [client, setClient] = useState<null | ClientProps>(null);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [userAccount, setUserAccount] = useState<UserAccountProps[]>([]);
  const [termOptions, setTermOptions] =
    useState<ListComponentProps[]>(LIST_INITIAL_STATE);
  const [templateList, setTemplateList] = useState<TemplateProps[]>([]);
  const [usersList, setUserList] = useState<UserProps[]>([]);
  const [projectList, setProjectList] = useState<ProjectProps[]>([]);
  const [projectTemplateList, setProjectTemplateList] = useState<
    ProjectTemplateProps[]
  >([]);
  const [activityList, setActivityList] = useState<ActivityProps[]>([]);
  const [taskList, setTaskList] = useState<TaskProps[]>([]);
  const [contactList, setContactList] = useState<ContactProps[]>([]);
  const [todoList, setToDoList] = useState<ToDoProps[]>([]);
  const [tagList, setTagList] = useState<TagProps[]>([]);
  const { showNotification } = useNotification();
  const [projectModal, setProjectModal] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    payload: ProjectProps | null;
  } | null>(null);
  const [activityModal, setActivityModal] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    payload: ActivityProps | null;
  } | null>(null);
  const [taskModal, setTaskModal] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    payload: TaskProps | null;
  } | null>(null);
  const [contactModal, setContactModal] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    payload: ContactProps | null;
  } | null>(null);
  const [projectSelected, setProjectSelected] = useState<string | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(ALERT_INITIAL_STATE);
      setIsError(ALERT_INITIAL_STATE);
    }, 3000);
  }, [isError, isSuccess]);

  useEffect(() => {
    setIsError(ALERT_INITIAL_STATE);
    setIsSuccess(ALERT_INITIAL_STATE);
    setContactModal(null);
    setActivityModal(null);
    setProjectModal(null);
    setTaskModal(null);
    handleChangeProjectSelected(null);
  }, []);

  useEffect(() => {
    clientIdSelected && getData("tags", clientIdSelected);
  }, [clientIdSelected]);

  useEffect(() => {
    let optionsCustomer: ListComponentProps[] = [];
    customerList.forEach((customer) => {
      optionsCustomer.push({
        label:
          customer.DisplayName && customer.FullyQualifiedName
            ? customer.DisplayName + " (" + customer.FullyQualifiedName + ")"
            : customer.CompanyName,
        value: customer.Id,
      });
    });
    optionsCustomer.length > 0 && setCustomerOptions(optionsCustomer);
  }, [customerList]);

  const handleChangeProjectSelected = (value: string | null) => {
    setProjectSelected(value);
  };

  const openItemModal = (
    type: "task" | "project" | "activity" | "contact",
    create: boolean,
    payload?: any,
    itemId?: string
  ) => {
    if (itemId && clientIdSelected) {
      const endpoint = `${type}/${clientIdSelected}/${itemId}`;
      handleRequest({
        endpoint,
        onSuccess: (response) => {
          response.data
            ? openModal(type, false, response.data)
            : showNotification("warning", "Warning", "Please try again");
        },
        onError: (response) => {
          showNotification(
            "warning",
            "Warning",
            response.error?.message || "Please try again"
          );
        },
      });
    } else {
      openModal(type, create, payload);
    }
  };

  const openModal = (
    type: "task" | "activity" | "project" | "contact",
    create: boolean,
    payload?: any
  ) => {
    switch (type) {
      case "task":
        setTaskModal({
          open: true,
          create: create,
          update: !create,
          payload: (payload as TaskProps) || null,
        });
        break;
      case "activity":
        setActivityModal({
          open: true,
          create: create,
          update: !create,
          payload: (payload as ActivityProps) || null,
        });
        break;
      case "project":
        setProjectModal({
          open: true,
          create: create,
          update: !create,
          payload: (payload as ProjectProps) || null,
        });
        break;
      case "contact":
        setContactModal({
          open: true,
          create: create,
          update: !create,
          payload: (payload as ContactProps) || null,
        });
        break;
      default:
        break;
    }
  };

  const closeItemModal = (
    type: "task" | "project" | "activity" | "contact"
  ) => {
    switch (type) {
      case "task":
        setTaskModal(null);
        break;
      case "activity":
        setActivityModal(null);
        break;
      case "project":
        setProjectModal(null);
        break;
      case "contact":
        setContactModal(null);
        break;
      default:
        break;
    }
  };

  const handleTour = (value: boolean) => {
    setIsTourOpen(value);
  };

  const handleWarning = (
    status: boolean,
    message: string,
    actionLabel?: string,
    action?: () => void
  ) => {
    setIsWarning({ status, message, actionLabel, action });
  };

  const handleSuccess = (status: boolean, message: string) => {
    setIsSuccess({ status, message });
  };

  const formatTermList = (list: { Name: string; Id: string }[]) => {
    let options: ListComponentProps[] = [];
    list.forEach((status) => {
      options.push({
        label: status.Name,
        value: status.Id,
      });
    });
    options.length > 0 && setTermOptions(options);
  };

  const resetContacts = () => {
    setContactList([]);
  };
  const getData = (
    endpoint:
      | "status"
      | "customer"
      | "invoice"
      | "deposit"
      | "credit"
      | "payment"
      | "journal"
      | "client"
      | "user-account"
      | "profile-info"
      | "term"
      | "template"
      | "users"
      | "projects"
      | "project-templates"
      | "activities"
      | "tasks"
      | "contacts"
      | "todos"
      | "tags",
    idClient: string,
    search?: string,
    filters?: any,
    showAlert?: boolean
  ) => {
    setIsError(ALERT_INITIAL_STATE);
    showAlert && setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    let searchEncoded = search && `search=${encodeURIComponent(search)}`;
    let filtersEncoded = filters && encodeURIComponent(JSON.stringify(filters));
    if (filtersEncoded) {
      searchEncoded
        ? (searchEncoded += `&filters=${filtersEncoded}`)
        : (searchEncoded = `filters=${filtersEncoded}`);
    }
    handleRequest({
      endpoint: searchEncoded
        ? `${endpoint}/${idClient}?${searchEncoded}`
        : `${endpoint}/${idClient}`,
      options,
      onSuccess: (response) => {
        switch (endpoint) {
          case "status":
            setStatusList(response.data);
            break;
          case "customer":
            setCustomerList(response.data);
            break;
          case "client":
            setClient(response.data);
            break;
          case "term":
            formatTermList(response.data);
            break;
          case "profile-info":
            changeProfileInfo(response.data);
            break;
          case "template":
            setTemplateList(response.data);
            break;
          case "users":
            setUserList(response.data);
            break;
          case "projects":
            setProjectList(response.data);
            break;
          case "project-templates":
            setProjectTemplateList(response.data);
            break;
          case "activities":
            setActivityList(response.data);
            break;
          case "tasks":
            setTaskList(response.data);
            break;
          case "contacts":
            setContactList(response.data);
            break;
          case "todos":
            setToDoList(response.data);
            break;
          case "tags":
            setTagList(response.data);
            break;
          default:
            setInvoiceList(response.data);
            break;
        }
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        // if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const getCustomersListByClient = ({
    idClient,
    filters,
    search,
    next,
  }: {
    idClient: string;
    filters?: { customer: string[] | null; location?: string };
    search?: string | null;
    next?: () => void;
  }) => {
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "GET",
    };
    let searchEncoded = search && encodeURIComponent(search);
    let apiEndpoint = searchEncoded
      ? `customer/${idClient}?search=${searchEncoded}`
      : `customer/${idClient}`;
    if (filters) {
      if (searchEncoded) {
        apiEndpoint = `${apiEndpoint}&filters=${JSON.stringify(filters)}`;
      } else {
        apiEndpoint = `${apiEndpoint}?filters=${JSON.stringify(filters)}`;
      }
    }
    handleRequest({
      endpoint: apiEndpoint,
      options,
      onSuccess: (response) => {
        setCustomerByClient(response.data);
        next && next();
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        // if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };
  const getCustomersListByProject = ({
    idClient,
    idProject,
    filters,
    search,
    next,
  }: {
    idClient: string;
    idProject: string;
    filters?: { customer: string[] | null };
    search?: string | null;
    next?: () => void;
  }) => {
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "GET",
    };
    let searchEncoded = search && encodeURIComponent(search);
    let apiEndpoint = searchEncoded
      ? `project/${idClient}/${idProject}?search=${searchEncoded}`
      : `project/${idClient}/${idProject}`;
    if (filters) {
      if (searchEncoded) {
        apiEndpoint = `${apiEndpoint}&filters=${JSON.stringify(filters)}`;
      } else {
        apiEndpoint = `${apiEndpoint}?filters=${JSON.stringify(filters)}`;
      }
    }
    handleRequest({
      endpoint: apiEndpoint,
      options,
      onSuccess: (response) => {
        setCustomerByProject(response.data.customers);
        next && next();
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        // if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const fetchUserAccounts = (idUser: string) => {
    try {
      setIsLoading(true);
      setIsError(ALERT_INITIAL_STATE);
      handleRequest({
        endpoint: `user-account/${idUser}`,
        onSuccess: (response) => {
          if (response.data) {
            setUserAccount(response.data);
          } else {
            setIsLoading(false);
            setIsError({ status: true, message: "Not Authorized" });
          }
        },
        onError: (e) => {
          setIsLoading(false);
          setIsError({ status: true, message: "Not Authorized" });
          if (e.message === "Not Authorized")
            onLogout(() => navigate(`/login?message=${ERROR_ENCRYPTED}`));
        },
      });
    } catch (e) {
      setIsLoading(false);
      setIsError({ status: true, message: "Not Authorized" });
    }
  };

  const getQbCompanyInfo = (
    realmId: string,
    next: (data: QuickbooksCompanyProps) => void
  ) => {
    try {
      setIsLoading(true);
      return handleRequest({
        endpoint: `qbcompany/${realmId}`,
        onSuccess: (response) => {
          if (response.data) {
            next(response.data);
          } else {
            return null;
          }
        },
        onError: (e) => {
          return null;
        },
      });
    } catch (e) {
      return null;
    }
  };

  const getReportData = (
    endpoint: "report" | "logs",
    idClient: string,
    filters: FilterProps,
    next?: (response: any) => void
  ) => {
    setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `${endpoint}/${idClient}/?filters=${JSON.stringify(filters)}`,
      options,
      onSuccess: (response) => {
        if (response.data.length > 0) {
          setIsSuccess({ status: true, message: "Data fetched successfully" });
          next && next(response.data);
        } else {
          setIsError({ status: true, message: "No data" });
        }
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        // if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleCreateData = ({
    endpoint,
    idClient,
    data,
    next,
    showAlert = true,
  }: {
    endpoint: string;
    idClient: string;
    data: any;
    next?: (newData: any) => void;
    showAlert?: boolean;
  }) => {
    showAlert && setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
    };
    handleRequest({
      endpoint: endpoint + "/" + idClient,
      options,
      onSuccess: (response) => {
        next && next(response.data);
        setIsLoading(false);
        if (response.data) {
          showAlert &&
            showNotification(
              "success",
              "Created",
              response.data?.message || "Your data has been saved."
            );
        } else {
          setIsSuccess({ status: true, message: "Created" });
        }
      },
      onError: (e) => {
        setIsLoading(false);
        showAlert &&
          showNotification(
            "warning",
            "Warning",
            e.error?.message || "Something has ocurred"
          );
        // // if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleEditData = ({
    endpoint,
    newData,
    next,
    nextOnFailed,
    showAlert = true,
  }: {
    endpoint: string;
    newData: any;
    next?: () => void;
    nextOnFailed?: (e: any) => void;
    showAlert?: boolean;
  }) => {
    showAlert && setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify({ ...newData }),
    };
    handleRequest({
      endpoint,
      options,
      onSuccess: (response) => {
        setIsLoading(false);
        showAlert &&
          showNotification(
            "success",
            "Updated",
            response.data?.message || "Your data has been saved."
          );
        next && next();
      },
      onError: (e) => {
        setIsLoading(false);
        showAlert &&
          showNotification(
            "warning",
            "Warning",
            e.error?.message || e.message || "Something has ocurred"
          );
        nextOnFailed && nextOnFailed(e);
        // // if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleDeleteData = ({
    endpoint,
    next,
  }: {
    endpoint: string;
    next: () => void;
  }) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "DELETE",
    };
    handleRequest({
      endpoint,
      options,
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Item Deleted" });
        next();
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: e.message || "Please try again" });
        // if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleBulkEdit = ({
    endpoint,
    items,
    newData,
    next,
  }: {
    endpoint: string;
    items: string[];
    newData: any;
    next?: () => void;
  }) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify({ idList: items, ...newData }),
    };
    handleRequest({
      endpoint,
      options,
      onSuccess: () => {
        next && next();
        setIsSuccess({ status: true, message: "Updated" });
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({
          status: true,
          message: e.message || "Please try again",
        });
        // if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const handleBulkDeleteData = (
    endpoint:
      | "status"
      | "invoice"
      | "payment"
      | "deposit"
      | "credit"
      | "journal",
    idClient: string,
    idList: string[]
  ) => {
    setIsLoading(true);
    setIsError(ALERT_INITIAL_STATE);
    let options: RequestInit = {
      method: "DELETE",
      body: JSON.stringify({ idList }),
    };
    handleRequest({
      endpoint: `${endpoint}-bulk/${idClient}`,
      options,
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Items Deleted" });
        getData(endpoint, idClient);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({
          status: true,
          message: e.message || "Please try again",
        });
        // if (e.message === "Not Authorized") onLogout(() => navigate("/login"));
      },
    });
  };

  const createOrUpdateTask = (
    customerInfo: CustomerProps,
    values: TaskProps,
    idClient: string,
    idTask?: string
  ) => {
    let filters: {
      active: boolean;
      project?: string;
      customer?: string;
      assignedTo?: boolean;
      $or?: any;
    } = {
      active: true,
    };
    if (pathname.split("/")[1] === "dashboard") {
      filters.assignedTo = true;
    } else {
      filters.customer = customerInfo._id;
    }
    if (taskModal && taskModal.create) {
      handleCreateData({
        endpoint: "tasks",
        idClient,
        data: {
          ...values,
          customer: customerInfo._id || values.customer?._id,
          project: projectSelected || values.project,
        },
        next: () => {
          getData("tasks", idClient, undefined, filters);
          closeItemModal("task");
        },
      });
    } else if (taskModal && taskModal.update && idTask) {
      handleEditData({
        endpoint: `task/${idClient}/${idTask}`,
        newData: values,
        next: () => {
          getData("tasks", idClient, undefined, filters);
          closeItemModal("task");
        },
      });
    }
  };

  const createOrUpdateActivity = (
    customerInfo: CustomerProps,
    values: ActivityProps,
    idClient: string,
    idActivity?: string
  ) => {
    let filters: {
      active: boolean;
      project?: string;
      customer?: string;
      assignedTo?: string;
      $or?: any;
    } = {
      active: true,
    };
    if (projectSelected) {
      filters.project = projectSelected;
    }
    if (customerInfo._id) {
      filters.customer = customerInfo._id;
    }
    if (activityModal && activityModal.create) {
      handleCreateData({
        endpoint: "activities",
        idClient,
        data: {
          ...values,
          customer: customerInfo._id,
          project: projectSelected,
        },
        next: () => {
          getData("activities", idClient, undefined, filters);
          closeItemModal("activity");
        },
      });
    } else if (activityModal && activityModal.update && idActivity) {
      handleEditData({
        endpoint: `activity/${idClient}/${idActivity}`,
        newData: values,
        next: () => {
          getData("activities", idClient, undefined, filters);
          closeItemModal("activity");
        },
      });
    }
  };

  const createOrUpdateContact = (
    customerInfo: CustomerProps,
    values: ContactProps,
    idClient: string
  ) => {
    if (contactModal && contactModal.create) {
      handleCreateData({
        endpoint: "contacts",
        idClient,
        data: {
          ...values,
          customer: customerInfo._id,
        },
        next: () => {
          getData("contacts", idClient, undefined, {
            customer: customerInfo._id,
          });
          closeItemModal("contact");
        },
      });
    } else {
      handleEditData({
        endpoint: `contact/${idClient}/${values._id}`,
        newData: values,
        next: () => {
          getData("contacts", idClient, undefined, {
            customer: customerInfo._id,
          });
          closeItemModal("contact");
        },
      });
    }
  };

  return (
    <DataContext.Provider
      value={{
        dataLoading: isLoading,
        dataSuccess: isSuccess,
        dataError: isError,
        dataWarning: isWarning,
        statusList,
        invoiceList,
        customerOptions,
        statusOptions,
        isModalStatusOpen,
        statusToEdit,
        client,
        isTourOpen,
        userAccount,
        termOptions,
        customerByClient,
        customerByProject,
        templateList,
        usersList,
        projectList,
        projectTemplateList,
        activityList,
        taskList,
        contactList,
        todoList,
        tagList,
        taskModal,
        projectModal,
        activityModal,
        contactModal,
        projectSelected,
        handleChangeProjectSelected,
        handleTour,
        openCloseModalStatus: (value: boolean) => SetIsModalStatusOpen(value),
        handleWarning,
        handleSuccess,
        selectStatusToEdit: (value: StatusProps | null) =>
          setStatusToEdit(value),
        resetContacts,
        getData,
        getReportData,
        handleCreateData,
        handleEditData,
        handleBulkEdit,
        handleDeleteData,
        handleBulkDeleteData,
        fetchUserAccounts,
        getQbCompanyInfo,
        getCustomersListByClient,
        getCustomersListByProject,
        openItemModal,
        closeItemModal,
        createOrUpdateTask,
        createOrUpdateActivity,
        createOrUpdateContact,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
