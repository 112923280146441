import { Modal } from "antd";
import NotificationContext from "./NotificationContext";
import { useContext, useEffect, useState } from "react";
import { connectSocket, getSocket } from "../services/socket";
import AuthContext from "./AuthContext";

import { useRequest } from "../hooks";
import { Notification } from "../interfaces/types/types";

type NotificationType = "success" | "error" | "info" | "warning";

type NotificationContextType = {
  notifications: Notification[];
  clearNotifications: (id?: string) => void;
  showNotification: (
    type: NotificationType,
    title: string,
    message: string,
    duration?: number
  ) => void;
  fetchNotifications: () => void;
};

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { handleRequest } = useRequest();
  const { profileInfo } = useContext(AuthContext);
  const [modal, contextHolder] = Modal.useModal();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    fetchNotifications();
    if (profileInfo?._id) {
      connectSocket(profileInfo?._id);
      const socket = getSocket();
      socket.on("newNotification", (notification: Notification) => {
        setNotifications((prev) => {
          if (!prev.find((n) => n._id === notification._id)) {
            return [...prev, notification];
          }
          return prev;
        });
      });
      socket.on("notificationRead", (id: string) => {
        setNotifications((prev) =>
          prev.map((n) => (n._id === id ? { ...n, read: true } : n))
        );
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [profileInfo]);

  const showNotification = (
    type: NotificationType,
    title: string,
    message: string,
    duration: number = 5
  ) => {
    let secondsToGo = duration;

    const instance = modal[type]({
      title,
      content: message,
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: message,
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);
  };

  const clearNotifications = (id?: string) => {
    if (id) {
      setNotifications((prev) => {
        return prev.filter((item) => item._id !== id);
      });
    } else {
      setNotifications([]);
    }
  };

  const fetchNotifications = async () => {
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `notifications`,
      options,
      onSuccess: (response) => {
        setNotifications((prev) => {
          const newNotifications = response.data.filter(
            (apiNotification: Notification) =>
              !prev.some((n) => n._id === apiNotification._id)
          );
          return [...prev, ...newNotifications]; // Merge without duplicates
        });
      },
      onError: (e) => {
        console.error("Error fetching notifications:", e);
      },
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
        notifications,
        clearNotifications,
        fetchNotifications,
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
