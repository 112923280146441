import { Button, Input } from "antd";
import React, { useContext, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";

import QuickbooksContext from "../../context/QuickbooksContext";

const { Search } = Input;

export const SearchInput = ({
  onSearch,
  placeholder,
}: {
  onSearch: (value: string) => void;
  placeholder?: string;
}) => {
  const { searchQuery, onChangeSearchQuery } = useContext(QuickbooksContext);

  useEffect(() => {
    onChangeSearchQuery("");
  }, []);

  return (
    <Search
      style={{ width: "80%", marginTop: 5 }}
      placeholder={placeholder || "Search"}
      allowClear
      value={searchQuery}
      onChange={(e) => onChangeSearchQuery(e.target.value)}
      size="middle"
      onSearch={onSearch}
      enterButton={
        <Button
          type="default"
          icon={<SearchOutlined />}
          disabled={!searchQuery}
          style={{ cursor: !searchQuery ? "not-allowed" : "pointer" }}
        ></Button>
      }
    />
  );
};
