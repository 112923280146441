import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import {
  ProjectProps,
  ProjectTemplateProps,
} from "../../interfaces/interfaces";
import Link from "antd/es/typography/Link";
import { TablePaginationConfig } from "antd/lib";
import { FilterValue } from "antd/es/table/interface";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const ProjectTemplateList = ({
  projectTemplateList,
  onSelectOne,
  rowSelection,
  hideColumns,
}: {
  projectTemplateList: ProjectTemplateProps[];
  onSelectOne: (project: ProjectTemplateProps) => void;
  rowSelection?: any;
  hideColumns?: boolean;
}) => {
  const [tableParams, setTableParams] = useState<TableParams>({});

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: hideColumns ? 5 : 100,
        total: projectTemplateList.length,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
  }, [projectTemplateList, hideColumns]);

  const columns: ColumnsType<ProjectTemplateProps> = [
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      className: "table-row",
      render: (_, { name }) => (
        <Tooltip title="Click to see Project Info">
          <Link>{name}</Link>
        </Tooltip>
      ),
      onCell: (record, index) => {
        return {
          onClick: () => onSelectOne(record),
        };
      },
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 1,
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table-row",
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
        multiple: 2,
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      className: "table-row",
      render: (_, { startDate }) => (
        <>{startDate ? new Date(startDate).toLocaleDateString() : "N/A"}</>
      ),
      sorter: {
        compare: (a, b) =>
          new Date(a.startDate || 0).getTime() -
          new Date(b.startDate || 0).getTime(),
        multiple: 3,
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      className: "table-row",
      render: (_, { endDate }) => (
        <>{endDate ? new Date(endDate).toLocaleDateString() : "N/A"}</>
      ),
      sorter: {
        compare: (a, b) =>
          new Date(a.endDate || 0).getTime() -
          new Date(b.endDate || 0).getTime(),
        multiple: 4,
      },
      ellipsis: {
        showTitle: false,
      },
    },

    {
      title: "Assigned to",
      dataIndex: "assignedTo",
      key: "assignedTo",
      minWidth: 60,
      render: (_, row) => <a>{row.assignedTo?.name}</a>,
      sorter: (a, b) =>
        (a.assignedTo ? a.assignedTo.name : "").localeCompare(
          b.assignedTo ? b.assignedTo.name : ""
        ),
    },
  ];
  return (
    <Table
      bordered
      columns={columns}
      rowSelection={rowSelection}
      dataSource={projectTemplateList}
      className="summary-client__table"
      pagination={tableParams.pagination}
      size="small"
      rowKey={"_id"}
      tableLayout="auto"
    />
  );
};
