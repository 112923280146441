import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  DatePicker,
  Switch,
  InputNumber,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { PROJECT_TEMPLATE_INITIAL_STATE } from "../../utils/data";
import { ProjectTemplateProps, UserProps } from "../../interfaces/interfaces";
import DataContext from "../../context/DataContext";
import dayjs from "dayjs";

interface Props {
  modalStatus: {
    open: boolean;
    create: boolean;
    update: boolean;
    payload?: ProjectTemplateProps | null;
  };
  onCancel: () => void;
  onSubmit: (values: ProjectTemplateProps) => void;
}

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Completed", label: "Completed" },
  { value: "On Hold", label: "On Hold" },
];

const fieldTypes = [
  { value: "String", label: "String" },
  { value: "Number", label: "Number" },
  { value: "Date", label: "Date" },
  { value: "Boolean", label: "Boolean" },
];

export const ModalCreateOrUpdateProjectTemplate = ({
  modalStatus: { open, payload },
  onCancel,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState<ProjectTemplateProps>(
    PROJECT_TEMPLATE_INITIAL_STATE
  );
  const { usersList } = useContext(DataContext);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(PROJECT_TEMPLATE_INITIAL_STATE);
      setValues(PROJECT_TEMPLATE_INITIAL_STATE);
    }
  }, [values]);

  useEffect(() => {
    if (payload) {
      setValues(payload);
    } else {
      setValues(PROJECT_TEMPLATE_INITIAL_STATE);
    }
  }, [payload]);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Modal
        title="Project Template"
        open={open}
        width={1000}
        footer={null}
        onOk={onCancel}
        onCancel={onCancel}
        centered
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={values}
          onFinish={() => {
            onSubmit(values);
            form.setFieldsValue(PROJECT_TEMPLATE_INITIAL_STATE);
            setValues(PROJECT_TEMPLATE_INITIAL_STATE);
          }}
        >
          <Row gutter={16}>
            {/* Project Name */}
            <Col span={8}>
              <Form.Item
                label="Template Name"
                name="name"
                rules={[
                  { required: true, message: "Project name is required" },
                ]}
              >
                <Input
                  placeholder="Enter template name"
                  value={values.name}
                  onChange={(event) =>
                    setValues((prev) => ({ ...prev, name: event.target.value }))
                  }
                />
              </Form.Item>
            </Col>

            {/* Status */}
            <Col span={8}>
              <Form.Item label="Status" name="status">
                <Select
                  placeholder="Select project template status"
                  options={statusOptions}
                  value={values.status}
                  onChange={(newValue) =>
                    setValues((prev) => ({
                      ...prev,
                      status: newValue,
                    }))
                  }
                ></Select>
              </Form.Item>
            </Col>

            {/* Assigned To */}
            <Col span={8}>
              <Form.Item label="Assigned To">
                <Select
                  showSearch
                  filterOption={filterOption}
                  value={values.assignedTo?._id}
                  placeholder="Select user"
                  style={{ width: "100%" }}
                  options={usersList.map((user) => ({
                    label: user.name,
                    value: user._id,
                  }))}
                  onChange={(value) => {
                    const selected = usersList.find(
                      (user) => user._id === value
                    );
                    setValues({ ...values, assignedTo: selected as UserProps });
                  }}
                />
              </Form.Item>
            </Col>

            {/* Start Date */}
            <Col span={11}>
              <Form.Item label="Start Date">
                <DatePicker
                  format="MM/DD/YYYY"
                  style={{ width: "100%" }}
                  value={values.startDate && dayjs(values.startDate)}
                  onChange={(date) =>
                    setValues((prev) => ({
                      ...prev,
                      startDate: date as any,
                    }))
                  }
                />
              </Form.Item>
            </Col>

            {/* End Date */}
            <Col span={11}>
              <Form.Item label="End Date">
                <DatePicker
                  format="MM/DD/YYYY"
                  style={{ width: "100%" }}
                  value={values.endDate && dayjs(values.endDate)}
                  onChange={(date) =>
                    setValues((prev) => ({ ...prev, endDate: date as any }))
                  }
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Active" name="active" valuePropName="checked">
                <Switch
                  value={values.active}
                  onChange={(checked) =>
                    setValues((prev) => ({ ...prev, active: checked }))
                  }
                />
              </Form.Item>
            </Col>

            {/* Description */}
            <Col span={24}>
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  rows={3}
                  placeholder="Enter project template description"
                  value={values.description}
                  onChange={(event) =>
                    setValues((prev) => ({
                      ...prev,
                      description: event.target.value,
                    }))
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Dynamic Custom Fields */}
          <Form.List name="customFields">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={16} key={key} align="middle">
                    {/* Field Name */}
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[
                          { required: true, message: "Field name is required" },
                        ]}
                      >
                        <Input placeholder="Field Name" />
                      </Form.Item>
                    </Col>

                    {/* Field Type */}
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, "type"]}
                        rules={[{ required: true, message: "Select a type" }]}
                      >
                        <Select
                          placeholder="Field Type"
                          options={fieldTypes}
                        ></Select>
                      </Form.Item>
                    </Col>

                    {/* Field Value (Dynamic Based on Type) */}
                    <Col span={8}>
                      <Form.Item shouldUpdate>
                        {({ getFieldValue }) => {
                          const fieldType = getFieldValue([
                            "customFields",
                            name,
                            "type",
                          ]);

                          if (fieldType === "String") {
                            return (
                              <Form.Item {...restField} name={[name, "value"]}>
                                <Input placeholder="Enter text" />
                              </Form.Item>
                            );
                          }

                          if (fieldType === "Boolean") {
                            return (
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                valuePropName="checked"
                              >
                                <Switch />
                              </Form.Item>
                            );
                          }

                          if (fieldType === "Date") {
                            return (
                              <Form.Item {...restField} name={[name, "value"]}>
                                <DatePicker style={{ width: "100%" }} />
                              </Form.Item>
                            );
                          }

                          if (fieldType === "Number") {
                            return (
                              <Form.Item {...restField} name={[name, "value"]}>
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            );
                          }

                          return null;
                        }}
                      </Form.Item>
                    </Col>

                    {/* Remove Field Button */}
                    <Col span={4}>
                      <Button type="link" danger onClick={() => remove(name)}>
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>

          {/* Buttons */}
          <Col span={24} style={{ textAlign: "right", marginTop: 16 }}>
            <Button type="default" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>
              Save Project
            </Button>
          </Col>
        </Form>
      </Modal>
    </>
  );
};
