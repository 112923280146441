import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { Select, Tag } from "antd";
import { TagProps } from "../../interfaces/interfaces";
import { getReadableRandomColor } from "../../utils/func/utils";
import { SelectProps } from "antd/lib";

type TagRender = SelectProps["tagRender"];

export const TagSelector = ({
  selectedTags,
  setSelectedTags,
  filtering = false,
  className = "",
  size = "large",
}: {
  selectedTags: TagProps[];
  setSelectedTags: (tags: TagProps[]) => void;
  filtering?: boolean;
  className?: string;
  size?: "small" | "middle" | "large";
}) => {
  const { clientIdSelected } = useContext(AuthContext);
  const { tagList, getData, handleCreateData } = useContext(DataContext);

  const handleTagChange = async (value: string[]) => {
    if (filtering) {
      const selected = tagList.filter((tag) => value.includes(tag._id));
      if (selected) {
        setSelectedTags(selected);
      }
      return;
    }
    const nameOrId = value[value.length - 1];
    if (nameOrId && !tagList.some((tag) => tag._id === nameOrId)) {
      try {
        handleCreateData({
          endpoint: "tags",
          idClient: clientIdSelected,
          data: {
            name: nameOrId,
            color: getReadableRandomColor(),
          },
          next: (newTag) => {
            // Fetch the updated list of tags after creating a new one
            getData("tags", clientIdSelected, undefined, undefined, false);
            setSelectedTags([...selectedTags, newTag]);
          },
          showAlert: false,
        });
      } catch (error) {
        console.error("Error creating tag:", error);
      }
    } else {
      const selected = tagList.filter((tag) => value.includes(tag._id));
      if (selected) {
        setSelectedTags(selected);
      }
    }
  };

  const tagRender: TagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const color =
      tagList.find((tag) => tag._id === value)?.color ||
      getReadableRandomColor();
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    );
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      mode="tags"
      tagRender={tagRender}
      size={size}
      style={{ minWidth: "200px" }}
      maxTagCount="responsive"
      className={`${className}`}
      placeholder={filtering ? "Filter by tag" : "Select or create tags"}
      onChange={handleTagChange}
      value={selectedTags.map((tag) => tag._id)}
      optionFilterProp="children"
      filterOption={filterOption}
      options={tagList.map((tag) => ({
        value: tag._id,
        label: tag.name,
      }))}
    ></Select>
  );
};
