import React, { useContext, useEffect, useState } from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge, Dropdown, List } from "antd";
import NotificationContext from "../../context/NotificationContext";
import DataContext from "../../context/DataContext";
import { Notification } from "../../interfaces/types/types";
import dayjs from "dayjs";
import { MenuProps } from "antd/lib";

export const NotificationBell = () => {
  const [open, setOpen] = useState(false);
  const { handleEditData, openItemModal } = useContext(DataContext);
  const { notifications, clearNotifications, fetchNotifications } =
    useContext(NotificationContext);
  const [items, setItems] = useState<MenuProps["items"]>([]);

  useEffect(() => {
    if (notifications.length > 0) {
      const notificationItems = notifications.map((notification) => ({
        key: notification._id,
        label: (
          <List.Item
            key={notification._id}
            style={{
              cursor: "pointer",
            }}
            extra={
              <small style={{ color: "#888" }}>
                {notification.createdAt
                  ? dayjs(notification.createdAt).format("MM/DD/YY HH:mm")
                  : ""}
              </small>
            }
            onClick={() => {
              handleEditData({
                endpoint: `notification/${notification?._id}/read`,
                newData: {
                  read: true,
                },
                next: () => {
                  handleOpenItem(notification);
                  clearNotifications(notification._id);
                },
                showAlert: false,
              });
            }}
          >
            <List.Item.Meta
              title={<strong>{notification.subject}</strong>}
              description={<p>{notification.messageBell}</p>}
            />
          </List.Item>
        ),
      }));

      setItems([
        ...notificationItems,
        {
          key: "clear-all",
          disabled: notifications.length === 0,
          danger: true,
          label: (
            <div
              style={{
                textAlign: "center",
                padding: "8px 0",
                cursor: "pointer",
                color: "#ff4d4f",
                fontWeight: "bold",
              }}
              onClick={() => {
                handleEditData({
                  endpoint: `notifications`,
                  newData: {
                    read: true,
                  },
                  next: () => {
                    clearNotifications();
                    fetchNotifications();
                  },
                  showAlert: false,
                });
              }}
            >
              Clear All Notifications
            </div>
          ),
        },
      ]);
    } else {
      setItems([]);
    }
  }, [notifications]);

  const handleOpenItem = (notification: Notification) => {
    switch (notification.itemType) {
      case "project":
        openItemModal("project", false, null, notification?.itemId);
        break;
      case "task":
        openItemModal("task", false, null, notification?.itemId);
        break;
      case "activity":
        openItemModal("activity", false, null, notification?.itemId);
        break;
      default:
        break;
    }
  };

  return (
    <Dropdown
      menu={{
        items,
        style: {
          maxHeight: "400px",
          overflowY: "auto",
        },
      }}
      trigger={["click"]}
      open={open}
      onOpenChange={setOpen}
      placement="bottomRight"
    >
      <Badge
        count={notifications.length}
        overflowCount={99}
        style={{ marginRight: "5px" }}
      >
        <BellOutlined
          style={{
            fontSize: "24px",
            cursor: "pointer",
            verticalAlign: "middle",
            color: "#17549a",
            marginRight: "5px",
          }}
        />
      </Badge>
    </Dropdown>
  );
};
