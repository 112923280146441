import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import React, { useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
const { Link, Title } = Typography;

export const DemoForm = () => {
  const { authLoading, handleLoginDemo } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [form] = Form.useForm();

  return (
    <Row justify="center" className="login__row">
      <Col md={24} xs={24} sm={24} lg={18} xl={18} className="login__col">
        <Title level={1} className="login__title">
          Request a Demo
        </Title>
        <Title level={5} className="login__subtitle">
          Enter your email
        </Title>
        <Form layout="vertical" className="row-col" form={form}>
          <Form.Item
            label="Your Name"
            name="name"
            className="login__form-item"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input
              placeholder="Your Name"
              // value={username}
              // onChange={(event) => setUsername(event.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="company"
            className="login__form-item"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input
              placeholder="Company Name"
              // value={username}
              // onChange={(event) => setUsername(event.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            className="login__form-item"
            rules={[
              {
                required: true,
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </Form.Item>

          <Form.Item
            className="login__form-item"
            style={{ textAlign: "center" }}
          >
            <Button
              style={{ width: "50%" }}
              type="link"
              className="login__form-button"
              onClick={() => {
                form
                  .validateFields()
                  .then(() => handleLoginDemo(username))
                  .catch((e) => console.log(e));
              }}
              disabled={authLoading}
            >
              Request Demo
            </Button>
          </Form.Item>
        </Form>
        <>
          <Divider>Or</Divider>
          <div style={{ textAlign: "center" }}>
            <Link href="https://www.quattroapps.com" target="_blank">
              Contact Quattro Software Sales Team
            </Link>
          </div>
        </>

        {/* <Divider>Already a Registered user?</Divider>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() =>
              window.location.replace("")
            }
          >
            Login to your app
          </Button>
        </div> */}
      </Col>
    </Row>
  );
};
