import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Modal,
  Row,
  Col,
  Checkbox,
} from "antd";
import { ActivityProps } from "../../interfaces/interfaces";
import { useContext, useEffect, useState } from "react";
import DataContext from "../../context/DataContext";
import { ACTIVITY_INITIAL_STATE } from "../../utils/data";
import dayjs from "dayjs";
import { TagSelector } from "../ui";

interface Props {
  modalStatus: {
    open: boolean;
    create: boolean;
    update: boolean;
    payload?: ActivityProps | null;
  } | null;
  onCancel: () => void;
  onSubmit: (values: ActivityProps) => void;
}

export const ModalCreateOrUpdateActivity = ({
  modalStatus,
  onCancel,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState<ActivityProps>(ACTIVITY_INITIAL_STATE);
  const { usersList } = useContext(DataContext);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(ACTIVITY_INITIAL_STATE);
      setValues(ACTIVITY_INITIAL_STATE);
    }
  }, [values]);

  useEffect(() => {
    if (modalStatus && modalStatus.payload) {
      setValues(modalStatus.payload);
    } else {
      setValues(ACTIVITY_INITIAL_STATE);
    }
  }, [modalStatus]);

  return (
    <Modal
      title="Activity"
      open={modalStatus?.open || false}
      width={1000}
      onOk={() => {
        onCancel();
        form.resetFields();
        setValues(ACTIVITY_INITIAL_STATE);
      }}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setValues(ACTIVITY_INITIAL_STATE);
      }}
      footer={null}
      centered
    >
      <Form
        layout="vertical"
        onFinish={() => onSubmit({ ...modalStatus?.payload, ...values })}
        form={form}
        initialValues={values}
      >
        <Row gutter={24}>
          {/* Left Column */}
          <Col span={12}>
            <Form.Item
              label="Type of Activity"
              name="typeOfTask"
              rules={[
                { required: true, message: "Please select an activity type" },
              ]}
            >
              <Select
                onChange={(value) =>
                  setValues({ ...values, typeOfTask: value })
                }
                options={[
                  { label: "Call", value: "Call" },
                  { label: "Meeting", value: "Meeting" },
                  { label: "Follow-up", value: "Follow-up" },
                  { label: "Email", value: "Email" },
                  { label: "Other", value: "Other" },
                ]}
                placeholder="Select type"
              />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input
                placeholder="Enter activity description"
                onChange={(event) =>
                  setValues({ ...values, description: event.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Time Logged">
              <DatePicker.RangePicker
                showTime={{ format: "HH:mm" }}
                popupStyle={{ position: "fixed", top: 250, left: 250 }}
                format="MM/DD/YYYY HH:mm"
                value={
                  values.startDate && values.endDate
                    ? [dayjs(values.startDate), dayjs(values.endDate)]
                    : undefined
                }
                onChange={(value) => {
                  if (value) {
                    setValues({
                      ...values,
                      startDate: value[0],
                      endDate: value[1],
                    });
                  }
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item label="Assigned To">
              <Select
                showSearch
                placeholder="Select user"
                style={{ width: "100%" }}
                value={values.assignedTo?._id}
                options={usersList.map((user) => ({
                  label: user.name,
                  value: user._id,
                }))}
                onChange={(value) =>
                  setValues({ ...values, assignedTo: value as any })
                }
              />
            </Form.Item>
          </Col>

          {/* Right Column */}
          <Col span={12}>
            <Form.Item label="Tags">
              <TagSelector
                selectedTags={values.tags || []}
                setSelectedTags={(selectedTags) => {
                  setValues({ ...values, tags: selectedTags });
                }}
              />
            </Form.Item>

            <Form.Item label="Priority" name="priority">
              <Select
                onChange={(value) => setValues({ ...values, priority: value })}
                options={[
                  { label: "Low", value: "Low" },
                  { label: "Medium", value: "Medium" },
                  { label: "High", value: "High" },
                ]}
                placeholder="Select priority"
              />
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: "Please select a status" }]}
            >
              <Select
                onChange={(value) => setValues({ ...values, status: value })}
                options={[
                  { label: "Planned", value: "Planned" },
                  { label: "In Progress", value: "In Progress" },
                  { label: "Completed", value: "Completed" },
                ]}
                placeholder="Select status"
              />
            </Form.Item>

            <Form.Item name="Billable" valuePropName="checked" label=" ">
              <Checkbox
                onChange={(e) =>
                  setValues({ ...values, isBillable: e.target.checked })
                }
              >
                Is Billable
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Notes" name="notes">
              <Input.TextArea
                rows={2}
                placeholder="Additional notes"
                onChange={(event) =>
                  setValues({ ...values, notes: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Buttons */}
        <Row justify="end">
          <Col>
            <Button
              type="default"
              onClick={onCancel}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save Activity
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
