import React, { useContext, useEffect, useState } from "react";
import {
  List,
  Pagination,
  Typography,
  Card,
  Input,
  Button,
  Space,
  Tooltip,
} from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  HomeOutlined,
  ApartmentOutlined,
  DownOutlined,
  UpOutlined,
  EditOutlined,
  EnvironmentOutlined,
  ReadOutlined,
  BorderlessTableOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { ContactProps } from "../../../interfaces/interfaces";
import { ModalCreateOrUpdateContact } from "./ModalCreateOrUpdateContact";
import { MODAL_INITIAL_STATE } from "../../../utils/data";
import DataContext from "../../../context/DataContext";
const { Search } = Input;

const { Text } = Typography;

interface Props {
  contacts: ContactProps[];
  showAdditionalInfo?: boolean;
  filterContacts: (searchTerm: string) => void;
  idClient?: string;
  next?: () => void;
}

export const ContactList = ({
  contacts,
  showAdditionalInfo,
  filterContacts,
  idClient,
  next,
}: Props) => {
  const { handleEditData } = useContext(DataContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [seeDetails, setSeeDetails] = useState<boolean[]>([]);
  const [contactModalStatus, setContactModalStatus] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    payload: ContactProps | null;
  } | null>(null);
  const [paginatedContacts, setPaginatedContacts] = useState<ContactProps[]>(
    []
  );
  const pageSize = 10;

  useEffect(() => {
    const newContacts = contacts.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    setPaginatedContacts(newContacts);
  }, [contacts, currentPage]);

  useEffect(() => {
    setSeeDetails(new Array(contacts.length).fill(false));
  }, [contacts]);

  const iconStyle = { color: "#1890ff", marginRight: 8 };

  return (
    <>
      <Card
        title={
          <>
            <Search
              placeholder="Search contacts..."
              allowClear
              onChange={(e) => {
                filterContacts(e.target.value);
                setCurrentPage(1);
              }}
            />
          </>
        }
        extra={
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={contacts.length}
            onChange={setCurrentPage}
            showSizeChanger={false}
          />
        }
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        styles={{
          body: {
            minHeight: 300,
            maxHeight: 300,
            overflow: "auto",
          },
        }}
      >
        <List
          itemLayout="vertical"
          dataSource={paginatedContacts}
          locale={{ emptyText: "Try Searching contacts" }}
          renderItem={(contact, index) => (
            <List.Item
              key={contact._id}
              extra={[
                <Space>
                  <Tooltip title="See Details">
                    <Button
                      size="small"
                      icon={
                        !!seeDetails[index] ? <UpOutlined /> : <DownOutlined />
                      }
                      onClick={() =>
                        setSeeDetails((prev) => {
                          const newDetails = prev.map((item, i) => {
                            if (index === i) {
                              return !item;
                            }
                            return item;
                          });
                          return newDetails;
                        })
                      }
                    ></Button>
                  </Tooltip>
                  <Tooltip title="Send Email">
                    <Button
                      icon={<MailOutlined />}
                      size="small"
                      disabled
                    ></Button>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <Button
                      size="small"
                      icon={<EditOutlined />}
                      onClick={() =>
                        setContactModalStatus({
                          open: true,
                          create: false,
                          update: true,
                          payload: contact,
                        })
                      }
                    ></Button>
                  </Tooltip>
                </Space>,
              ]}
            >
              <List.Item.Meta
                title={`${contact.title} ${contact.name}`}
                // description={<></>}
              />
              {seeDetails[index] && (
                <div>
                  {contact.relationship && (
                    <div>
                      <LinkOutlined style={iconStyle} />
                      <Text>{contact.relationship}</Text>
                    </div>
                  )}
                  {contact.customer && contact.customer.DisplayName && (
                    <div>
                      <ApartmentOutlined style={iconStyle} />
                      <Text>{contact.customer.DisplayName}</Text>
                    </div>
                  )}
                  {contact.email && (
                    <div>
                      <MailOutlined style={iconStyle} />
                      <Text>{contact.email}</Text>
                    </div>
                  )}
                  {contact.phone && (
                    <>
                      <div>
                        <PhoneOutlined style={iconStyle} />
                        <Text>{contact.phone}</Text>
                      </div>
                    </>
                  )}
                  {contact.address &&
                    contact.address !== "" &&
                    contact.address !== " " &&
                    !contact.address.includes("undefined") && (
                      <div>
                        <HomeOutlined style={iconStyle} />
                        <Text>{contact.address}</Text>
                      </div>
                    )}
                  {contact.city && (
                    <>
                      <EnvironmentOutlined style={iconStyle} />
                      <Text>
                        {contact.city} {contact.state && `, ${contact.state}`}
                      </Text>
                      <br />
                    </>
                  )}
                  {contact.zip && (
                    <>
                      <BorderlessTableOutlined style={iconStyle} />
                      <Text>{contact.zip}</Text>
                      <br />
                    </>
                  )}
                  {contact.notes && (
                    <>
                      <ReadOutlined style={iconStyle} />
                      <Text>{contact.notes}</Text>
                    </>
                  )}
                </div>
              )}
            </List.Item>
          )}
        />
      </Card>
      <ModalCreateOrUpdateContact
        onCancel={() => {
          setContactModalStatus(MODAL_INITIAL_STATE);
        }}
        modalStatus={contactModalStatus}
        onSubmit={(values) => {
          idClient &&
            handleEditData({
              endpoint: `contact/${idClient}/${values._id}`,
              newData: values,
              next: () => {
                next && next();
                setContactModalStatus(null);
              },
            });
        }}
      />
    </>
  );
};
