import React from "react";
import desktopLogo from "../../utils/company/assets/company_logo.png";
import mobileLogo from "../../utils/company/assets/company_logo.png";
import { Grid } from "antd";

export const Logo = ({ mobile }: { mobile: boolean }) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  if (xs) {
    return (
      <img src={mobileLogo} alt="Company Logo" className="logo-img-mobile" />
    );
  } else if (mobile) {
    return (
      <img src={desktopLogo} alt="Company Logo" className="logo-img-closed" />
    );
  } else {
    return <img src={desktopLogo} alt="Company Logo" className="logo-img" />;
  }
};
