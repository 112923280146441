import { Button, Row, Tabs, TabsProps, Tooltip, Tour } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CardComponent,
  CardTitle,
  ColumnComponent,
  TagSelector,
} from "../../components/ui";
import Table from "antd/es/table";
import DataContext from "../../context/DataContext";
import { useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { DownloadOutlined, FolderAddOutlined } from "@ant-design/icons";
import { SearchInput } from "../../components/functional";
import {
  CustomerProps,
  ProjectProps,
  TagProps,
} from "../../interfaces/interfaces";
import { useDemoModal } from "../../hooks";
import { exportProjectList } from "../../utils/func/ExportFile";
import { CustomerDrawer } from "../../components/customer";
import {
  DrawerCreateOrUpdateProject,
  ProjectList,
} from "../../components/project";

export const ProjectListPage = () => {
  const { idClient } = useParams();
  const { clientInfo, isDemoEnv, getClientInformation } =
    useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const {
    getData,
    projectList,
    projectModal,
    openItemModal,
    closeItemModal,
    handleTour,
    handleCreateData,
    handleEditData,
    getCustomersListByClient,
  } = useContext(DataContext);
  const [selectedRowsToEdit, setSelectedRowToEdit] = useState<React.Key[]>([]);
  const [tagsSelected, setTagsSelected] = useState<TagProps[]>([]);
  const [customerDrawerStatus, setCustomerDrawerStatus] = useState<{
    open: boolean;
    idCustomer: string | null;
  }>({ open: false, idCustomer: "" });
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);

  useEffect(() => {
    if (idClient) {
      getData("projects", idClient, undefined, { active: true });
      getData("project-templates", idClient, undefined, { active: true });
      getData("users", idClient, undefined, { active: true });
      getCustomersListByClient({ idClient });
      getClientInformation(idClient, () => {});
    }
  }, [idClient]);

  useEffect(() => {
    if (isDemoEnv) {
      let tourOpened = localStorage.getItem("tour-summary");
      if (!tourOpened) {
        handleTour(true);
        localStorage.setItem("tour-customer", "true");
      }
    }
  }, []);

  const onChangeSelectedRowsToEdit = (newSelectedRowToEdit: React.Key[]) => {
    setSelectedRowToEdit(newSelectedRowToEdit);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowsToEdit,
    onChange: onChangeSelectedRowsToEdit,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const createOrUpdateProject = (
    values: ProjectProps,
    idClient: string,
    idProject?: string
  ) => {
    if (projectModal?.create) {
      handleCreateData({
        endpoint: "projects",
        idClient,
        data: values,
        next: () => getData("projects", idClient, undefined, { active: true }),
      });
    } else if (projectModal?.update && idProject) {
      handleEditData({
        endpoint: `project/${idClient}/${idProject}`,
        newData: values,
        next: () => getData("projects", idClient, undefined, { active: true }),
      });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Active",
      children: (
        <ProjectList
          projectList={projectList}
          onSelectOne={(project) => openItemModal("project", false, project)}
          rowSelection={rowSelection}
        />
      ),
    },
    {
      key: "2",
      label: "Archived",
      children: (
        <ProjectList
          projectList={projectList}
          onSelectOne={(project) => openItemModal("project", false, project)}
          rowSelection={rowSelection}
        />
      ),
    },
  ];

  const onChange = (key: string) => {
    if (key === "1") {
      idClient &&
        getData("projects", idClient, undefined, {
          active: true,
        });
    } else if (key === "2") {
      idClient &&
        getData("projects", idClient, undefined, {
          active: false,
        });
    }
  };

  const handleFilterByTags = (selectedTags: TagProps[]) => {
    setTagsSelected(selectedTags);
    if (selectedTags.length > 0) {
      if (idClient) {
        getData("projects", idClient, undefined, {
          active: true,
          tags: {
            $in: selectedTags.map((tag) => tag._id),
          },
        });
      }
    } else {
      idClient &&
        getData("projects", idClient, undefined, {
          active: true,
        });
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title={clientInfo?.name + " - Projects"} />
        </ColumnComponent>
        <ColumnComponent>
          <div ref={ref1}>
            <CardComponent
              className="summary-client__card"
              title={""}
              actions={[
                <TagSelector
                  className="summary-client__card__actions__select"
                  size="middle"
                  selectedTags={tagsSelected}
                  setSelectedTags={(selected) => handleFilterByTags(selected)}
                  filtering={true}
                />,
                <div style={{ alignSelf: "center" }}>
                  <SearchInput
                    onSearch={(value: string) => {
                      if (value) {
                        idClient &&
                          getData("projects", idClient, value, {
                            active: true,
                          });
                      } else {
                        idClient &&
                          getData("projects", idClient, undefined, {
                            active: true,
                          });
                      }
                    }}
                  />
                </div>,
                <div ref={ref2}>
                  <Tooltip title="Create new Project" placement="left">
                    <Button
                      type="link"
                      icon={<FolderAddOutlined />}
                      size="large"
                      onClick={() => openItemModal("project", true)}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref5}>
                  <Tooltip
                    title={
                      selectedRowsToEdit.length > 0
                        ? "Export Selected Data"
                        : "Export Data"
                    }
                    placement="left"
                  >
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      size="large"
                      onClick={() => {
                        exportProjectList(
                          projectList,
                          selectedRowsToEdit as string[]
                        );
                      }}
                    />
                  </Tooltip>
                </div>,
              ]}
            ></CardComponent>
          </div>
        </ColumnComponent>
        <ColumnComponent>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </ColumnComponent>
      </Row>

      {idClient && (
        <CustomerDrawer
          open={customerDrawerStatus.open}
          idClient={idClient}
          idCustomer={customerDrawerStatus.idCustomer}
          onSelectOne={(record: CustomerProps) =>
            setCustomerDrawerStatus({ open: true, idCustomer: record.Id })
          }
          onClose={() =>
            setCustomerDrawerStatus({ open: false, idCustomer: "" })
          }
        />
      )}
      <DrawerCreateOrUpdateProject
        modalStatus={projectModal}
        onSubmit={(values) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            if (clientInfo && idClient) {
              createOrUpdateProject(values, idClient, values._id);
              closeItemModal("project");
            }
          }
        }}
        handleCreateLocation={(locationName) => {
          handleEditData({
            endpoint: `client/${idClient}`,
            newData: {
              newLocation: locationName,
            },
            next: () => {
              idClient && getClientInformation(idClient, () => {});
            },
          });
        }}
        handleCreateNewProjectStatus={(projectStatusName) => {
          handleEditData({
            endpoint: `client/${idClient}`,
            newData: {
              newProjectStatus: projectStatusName,
            },
            next: () => {
              idClient && getClientInformation(idClient, () => {});
            },
          });
        }}
        onCancel={() => {
          closeItemModal("project");
        }}
      />
      {/* <Tour open={isTourOpen} onClose={() => handleTour(false)} steps={steps} /> */}
      <DemoModal />
    </>
  );
};
