import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { CustomerProps } from "../../interfaces/interfaces";
import { Tooltip, Table, Popover, List, Button, Typography } from "antd";
import { USDollar } from "../../utils/func/currency";
import { TablePaginationConfig } from "antd/lib";
import { FilterValue } from "antd/es/table/interface";

const { Link } = Typography;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const CustomerTable = ({
  customers,
  onSelectOne,
  rowSelection,
}: {
  customers: CustomerProps[];
  onSelectOne: (record: CustomerProps, idProject?: string) => void;
  rowSelection: any;
}) => {
  const [tableParams, setTableParams] = useState<TableParams>({});

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: 100,
        total: customers?.length || 0,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
  }, [customers]);

  const getProjectList = (customer: CustomerProps) => {
    return (
      <List
        dataSource={customer.Projects}
        renderItem={(item) => (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => onSelectOne(customer, item.id._id)}
            >
              {item.id.name}
            </Button>
          </div>
        )}
      />
    );
  };

  const columns: ColumnsType<CustomerProps> = [
    {
      title: "Client",
      dataIndex: "DisplayName",
      key: "DisplayName",
      className: "table-row",
      render: (_, { DisplayName }) => (
        <Tooltip title="Click to see Customer Info">
          <Link>{DisplayName}</Link>
        </Tooltip>
      ),
      onCell: (record, index) => {
        return {
          onClick: () => onSelectOne(record, undefined),
        };
      },
      sorter: {
        compare: (a, b) => a.DisplayName.localeCompare(b.DisplayName),
        multiple: 1,
      },

      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Email",
      dataIndex: "PrimaryEmailAddr",
      key: "PrimaryEmailAddr",
      className: "table-row",
      render: (_, { PrimaryEmailAddr }) => (
        <>{PrimaryEmailAddr ? PrimaryEmailAddr.Address : ""}</>
      ),
      sorter: {
        compare: (a, b) =>
          (a.PrimaryEmailAddr?.Address || "").localeCompare(
            b.PrimaryEmailAddr?.Address || ""
          ),
        multiple: 1,
      },

      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Phone",
      dataIndex: "PrimaryPhone",
      key: "PrimaryPhone",
      className: "table-row",
      render: (_, { PrimaryPhone }) => <>{PrimaryPhone?.FreeFormNumber}</>,
      sorter: {
        compare: (a, b) =>
          (a.PrimaryPhone?.FreeFormNumber || "").localeCompare(
            b.PrimaryPhone?.FreeFormNumber || ""
          ),
        multiple: 1,
      },

      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Projects",
      dataIndex: "projects",
      key: "Projects",
      minWidth: 90,
      className: "table-row align-center pointer",
      render: (_, row) => (
        <Popover content={getProjectList(row)} title="Projects">
          <Link>{row.Projects?.length}</Link>
        </Popover>
      ),
      sorter: {
        compare: (a, b) =>
          (a.Projects?.length || 0) - (b.Projects?.length || 0),
        multiple: 5,
      },
    },
    {
      title: "Balance",
      dataIndex: "Balance",
      key: "Balance",
      width: 100,
      className: "align-right table-row",
      render: (_, { Balance }) => (
        <div
          style={{
            color: Balance && Balance > 0 ? "red" : "green",
            fontWeight: 1000,
            textAlign: "right",
          }}
        >
          {USDollar.format(Balance || 0)}
        </div>
      ),
      sorter: {
        compare: (a, b) => a.Balance - b.Balance,
        multiple: 2,
      },

      ellipsis: {
        showTitle: false,
      },
    },
  ];

  return (
    <Table
      bordered
      columns={columns}
      rowSelection={rowSelection}
      dataSource={customers}
      pagination={tableParams.pagination}
      size="small"
      rowKey={"Id"}
      scroll={{ y: "70vh", x: "100%" }}
      tableLayout="auto"
    />
  );
};
