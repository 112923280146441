import { Button, Card, Col, List, Progress, Row, Tooltip, Tour } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import DataContext from "../../context/DataContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ProjectProps, TagProps, ToDoProps } from "../../interfaces/interfaces";
import { TaskList } from "../../components/task";
import {
  DrawerCreateOrUpdateProject,
  ProjectList,
} from "../../components/project";
import { ContactList } from "../../components/customer";
import AuthContext from "../../context/AuthContext";
import { useDemoModal } from "../../hooks";
import { ModalCreateOrUpdateToDo } from "../../components/user";
import { PlusOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Base64 } from "js-base64";
import { CardTitle, TagSelector } from "../../components/ui";

export const DashboardClient = () => {
  const navigate = useNavigate();
  const { idClient } = useParams();
  const { isDemoEnv, profileInfo, getClientInformation, onLogout } =
    useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [completionRate, setCompletionRate] = useState<number>(0);
  const [todoModalStatus, setTodoModalStatus] = useState<{
    open: boolean;
    create: boolean;
    update: boolean;
    payload: ToDoProps | null;
  } | null>(null);
  const [tagsSelected, setTagsSelected] = useState<TagProps[]>([]);
  const {
    getData: getListData,
    taskList,
    projectList,
    contactList,
    todoList,
    projectModal,
    openItemModal,
    closeItemModal,
    resetContacts,
    handleWarning,
    handleCreateData,
    handleEditData,
    getCustomersListByClient,
  } = useContext(DataContext);

  const [searchParams] = useSearchParams();
  const refWarning = useRef(false);

  // const ref1 = useRef(null);
  // const ref2 = useRef(null);
  // const ref3 = useRef(null);
  // const ref4 = useRef(null);
  // const ref5 = useRef(null);
  // const ref6 = useRef(null);
  // const ref7 = useRef(null);
  // const ref8 = useRef(null);

  useEffect(() => {
    if (searchParams.get("message")) {
      const message = searchParams.get("message")?.toString();
      if (message) {
        const messageDecoded = Base64.decode(message);
        if (messageDecoded === "Not Authorized") {
          onLogout();
        } else {
          !refWarning.current && handleWarning(true, messageDecoded);
          refWarning.current = true;
          navigate("/dashboard");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (idClient) {
      getListData("projects", idClient, undefined, {
        assignedTo: profileInfo?._id,
        active: true,
      });
      getListData("project-templates", idClient, undefined, {
        active: true,
      });
      getListData("users", idClient);
      getListData("todos", idClient, undefined, { dueDate: new Date() });
      getClientInformation(idClient, () => {});
      getCustomersListByClient({ idClient });
      getListData("tasks", idClient, undefined, {
        assignedTo: true,
        active: true,
      });
    }
  }, [idClient, profileInfo]);

  useEffect(() => {
    const completedTasks = todoList.filter((task) => task.done).length;
    const totalTasks = todoList.length;
    const completionRate = totalTasks
      ? Math.round((completedTasks / totalTasks) * 100)
      : 0;
    setCompletionRate(completionRate);
  }, [todoList]);

  useEffect(() => {
    closeItemModal("task");
    closeItemModal("project");
    closeItemModal("contact");
    closeItemModal("activity");
  }, []);

  const completeTask = (idToDo: string) => {
    idClient &&
      handleEditData({
        endpoint: `todo/${idClient}/${idToDo}`,
        newData: {
          done: true,
        },
        next: () =>
          getListData("todos", idClient, undefined, { dueDate: new Date() }),
      });
  };

  const createOrUpdateProject = (
    values: ProjectProps,
    idClient: string,
    idProject?: string
  ) => {
    if (projectModal && projectModal.create) {
      handleCreateData({
        endpoint: "projects",
        idClient,
        data: values,
        next: () => {
          getListData("projects", idClient, undefined, {
            assignedTo: profileInfo?._id,
            active: true,
          });
          closeItemModal("project");
        },
      });
    } else if (projectModal?.update && idProject) {
      handleEditData({
        endpoint: `project/${idClient}/${idProject}`,
        newData: values,
        next: () => {
          getListData("projects", idClient, undefined, {
            assignedTo: profileInfo?._id,
            active: true,
          });
          closeItemModal("project");
        },
      });
    }
  };

  const handleFilterByTags = (selectedTags: TagProps[]) => {
    setTagsSelected(selectedTags);
    if (selectedTags.length > 0) {
      if (idClient) {
        getListData("tasks", idClient, undefined, {
          assignedTo: true,
          active: true,
          tags: {
            $in: selectedTags.map((tag) => tag._id),
          },
        });
        getListData("projects", idClient, undefined, {
          assignedTo: profileInfo?._id,
          active: true,
          tags: {
            $in: selectedTags.map((tag) => tag._id),
          },
        });
      }
    } else {
      idClient &&
        getListData("tasks", idClient, undefined, {
          assignedTo: true,
          active: true,
        });
      idClient &&
        getListData("projects", idClient, undefined, {
          assignedTo: profileInfo?._id,
          active: true,
        });
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={24}>
          <CardTitle
            title=""
            extra={[
              <div style={{ margin: 8 }} key="extra">
                <TagSelector
                  selectedTags={tagsSelected}
                  setSelectedTags={(selected) => handleFilterByTags(selected)}
                  filtering={true}
                />
              </div>,
            ]}
          />
        </Col>
        {/* Activity List with Upcoming Deadlines */}
        <Col xs={24} md={24} xl={16}>
          <TaskList
            hideColumns={true}
            taskList={taskList}
            onAddNewTask={() => openItemModal("task", true)}
            onEditTask={(task) => openItemModal("task", false, task)}
            onDuplicateTask={(task) =>
              openItemModal("task", true, { ...task, _id: "" })
            }
          />
        </Col>
        {/* Contact List */}
        <Col xs={24} md={24} xl={8}>
          <ContactList
            contacts={contactList}
            showAdditionalInfo={true}
            filterContacts={(search) => {
              if (search) {
                idClient && getListData("contacts", idClient, search);
              } else {
                resetContacts();
              }
            }}
            idClient={idClient}
            next={() => {
              idClient && getListData("contacts", idClient);
            }}
          />
        </Col>

        {/* Project List */}
        <Col xs={24} md={24} xl={16}>
          <Card title="Projects">
            <ProjectList
              projectList={projectList}
              onSelectOne={(project) =>
                openItemModal("project", false, project)
              }
              hideColumns={true}
            />
          </Card>
        </Col>
        {/* To-Do List with Completion Rate */}
        <Col xs={24} md={24} xl={8}>
          <Card
            title="To-Do List"
            extra={
              <Tooltip title="New">
                <Button
                  icon={<PlusOutlined />}
                  onClick={() =>
                    setTodoModalStatus({
                      open: true,
                      create: true,
                      update: false,
                      payload: null,
                    })
                  }
                ></Button>
              </Tooltip>
            }
          >
            <Progress percent={completionRate} status="active" />
            <List
              itemLayout="horizontal"
              size="small"
              locale={{ emptyText: "Nohing To do" }}
              dataSource={todoList.filter((task) => !task.done)}
              renderItem={(task) => (
                <List.Item>
                  <List.Item.Meta title={task.description} />
                  <Tooltip title="Mark as Done">
                    <Button
                      color="danger"
                      style={{ border: "none" }}
                      onClick={() => completeTask(task._id)}
                      icon={<CheckCircleOutlined style={{ color: "green" }} />}
                    ></Button>
                  </Tooltip>
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
      <DrawerCreateOrUpdateProject
        modalStatus={projectModal}
        onSubmit={(values) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            if (idClient) {
              createOrUpdateProject(values, idClient, values._id);
            }
          }
        }}
        handleCreateLocation={(locationName) => {
          handleEditData({
            endpoint: `client/${idClient}`,
            newData: {
              newLocation: locationName,
            },
            next: () => {
              idClient && getClientInformation(idClient, () => {});
            },
          });
        }}
        handleCreateNewProjectStatus={(projectStatusName) => {
          handleEditData({
            endpoint: `client/${idClient}`,
            newData: {
              newProjectStatus: projectStatusName,
            },
            next: () => {
              idClient && getClientInformation(idClient, () => {});
            },
          });
        }}
        onCancel={() => {
          closeItemModal("project");
        }}
      />
      <ModalCreateOrUpdateToDo
        modalStatus={todoModalStatus}
        onCancel={() => {
          setTodoModalStatus(null);
        }}
        onSubmit={(values) => {
          idClient &&
            handleCreateData({
              endpoint: "todos",
              idClient,
              data: values,
              next: () => {
                getListData("todos", idClient, undefined, {
                  dueDate: new Date(),
                });
                setTodoModalStatus(null);
              },
            });
        }}
      />
      <DemoModal />
    </>
  );
};
