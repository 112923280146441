import dayjs, { Dayjs } from "dayjs";

export const getDaysDiffNewAccount = (firstDate: Dayjs) => {
  let today = dayjs();
  return 14 - today.diff(firstDate, "days");
};

export const validateEmail = (email: string) => {
  return !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateEmailsString = (emails: string) => {
  if (emails.includes(",")) {
    const emailArray = emails.split(",");
    return emailArray.every((email) => validateEmail(email));
  } else {
    return validateEmail(emails);
  }
};

const colors = [
  "#003f5c", // Deep Blue
  "#2f4b7c", // Dark Indigo
  "#665191", // Muted Purple
  "#a05195", // Plum
  "#d45087", // Dark Pink
  "#f95d6a", // Coral Red
  "#ff7c43", // Deep Orange
  "#ffa600", // Golden Yellow
  "#4e79a7", // Steel Blue
  "#76b7b2", // Teal
  "#59a14f", // Deep Green
  "#edc949", // Goldenrod
  "#af7aa1", // Muted Lavender
  "#ff9da7", // Soft Pink
  "#b07aa1", // Warm Violet
  "#ff6361", // Strong Red
  "#bc5090", // Bold Purple
  "#003f88", // Corporate Blue
  "#546e7a", // Cool Gray
  "#37474f", // Dark Charcoal
];

export const getReadableRandomColor = (): string => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};
